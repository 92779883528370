/** @format */

import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import DownloadIcon from "@mui/icons-material/Download";
import ConfirmDialog from "./ConfirmDialog";

const UploadRider = ({ bookingid, bookingData }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [downloadURL, setDownloadURL] = useState((bookingData && bookingData.rider) || null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      return;
    }

    try {
      const storage = getStorage();
      //   const storageRef = ref(storage, "riders/" + selectedFile.name);
      const storageRef = ref(storage, "riders/" + bookingid);
      const uploadTask = uploadBytesResumable(storageRef, selectedFile);

      uploadTask.on("state_changed", (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setUploadProgress(progress);
      });

      await new Promise((resolve, reject) => {
        uploadTask.on("state_changed", null, reject, resolve);
      });

      const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
      setDownloadURL(downloadURL);
      console.log("File uploaded successfully");
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  useEffect(() => {
    async function updateBookingWithRider() {
      await updateDoc(doc(db, "Bookings", bookingid), {
        rider: downloadURL,
      });
    }
    updateBookingWithRider();
    // alert("Rider uploaded successfully");
  }, [downloadURL]);

  return (
    <div>
      {!downloadURL && (
        <>
          <input type="file" accept=".pdf,.doc,.docx" onChange={handleFileChange} />
          <Button variant="outlined" size="small" onClick={handleUpload}>
            Upload
          </Button>
        </>
      )}
      {uploadProgress > 0 && !downloadURL && (
        <div style={{ marginTop: "10px" }}>
          <LinearProgress variant="determinate" value={uploadProgress} />
        </div>
      )}
      {downloadURL && (
        <p>
          <a href={downloadURL} target="_blank" rel="noopener noreferrer">
            <DownloadIcon />
          </a>
          <ConfirmDialog onConfirm={() => setDownloadURL(null)}>Change</ConfirmDialog>
        </p>
      )}
    </div>
  );
};

export default UploadRider;
