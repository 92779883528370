/** @format */

import { Button, Box, Typography, Grid, CircularProgress } from "@mui/material";
import { collection, doc, getDoc, getDocs, query, Timestamp, updateDoc, where } from "firebase/firestore";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase";
import EmailProgressBar from "../helpers/EmailProgressBar";
import LoadingDots from "../components/UI helpers/LoadingDots";
import axios from "axios";
import BatchAgreementModal from "../components/Documents/BatchAgreementModal";
import { wait } from "../components/MiscUIComponents/Wait";

const PassedVerification = () => {
  const dispatch = useDispatch();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const response = urlParams.get("id_token");
  const userId = urlParams.get("user");
  const agreementId = urlParams.get("agreementId");
  const socialId = urlParams.get("socialId");
  const phoneNumber = urlParams.get("phoneNumber");
  const type = urlParams.get("type");
  const returnAddress = urlParams.get("returnAddress");
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const currentPdfDocHTML = useSelector((state) => state.counter.currentPdfDocHTML);
  console.log("currentPdfDocHTML", currentPdfDocHTML);
  const currentAgreement = useSelector((state) => state.counter.currentAgreement);
  console.log("currentAgreement", currentAgreement);
  const date = new Date().toLocaleString();
  let currentBooking = [];
  const [sentArray, setSentArray] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [doneArchiving, setDoneArchiving] = React.useState(false);
  const [quickOpenClose, setQuickOpenClose] = React.useState(true);
  // const [agreement, setAgreement] = React.useState();
  const navigate = useNavigate();
  let agreement;

  useEffect(() => {
    async function getData() {
      // Set agreement
      const docSnap = await getDoc(doc(db, "Agreements", agreementId));
      if (docSnap.exists()) {
        // setAgreement(docSnap.data());
        agreement = docSnap.data();
        console.log(docSnap.data());
      } else {
        console.log("No such document!");
      }
      //
      // const docSnap = await getDoc(doc(db, "Bookings", bookingId));
      // if (docSnap.exists()) {
      //   currentBooking = docSnap.data();
      //   console.log(currentBooking);
      // } else {
      //   console.log("No such document!");
      // }

      // This is the signature
      let Signature = { userId: userId, agreementId: agreementId, date: date, socialId: socialId, phoneNumber: phoneNumber };

      //If authorization is venueSignature store signature in booking and change status
      // NOTE This code has been altered since (Dec2023) since moving to "Batch" Agreement Signing instead of single bookings.
      if (type === "venueSignature") {
        // Ta fram lista på bookingids från agreementId
        // alert(`venueSignature in process for  ${agreementId}`);
        let bookingids = [];
        const querySnapshot = await getDocs(query(collection(db, "Bookings"), where("agreementId", "==", agreementId)));
        querySnapshot.forEach((doc) => {
          console.log("STATUS", doc.data().status);
          doc.data().status === "Pending Request" && bookingids.push(doc.data().bookingid);
        });

        // mappa genom bookingids och spara ny status och venueSignature
        bookingids.map(async (item) => {
          console.log("ITEM", item);
          let venueSignature = await Signature;
          let status = "Awaiting Artist";
          console.log("updating booking:", item, venueSignature);
          await updateDoc(doc(db, "Bookings", item), {
            venueSignature: venueSignature,
            status: status,
            updated: Timestamp.fromDate(new Date()),
          });

          await updateDoc(doc(db, "Agreements", agreementId), {
            venueSignature: venueSignature,
            status: status,
            bookingids: bookingids,
          });
        });
        // await emailSuccess("venueSignature", agreementId);
        // navigate("/Owner/Home");
      }

      //If authorization is agentSignature store signature in booking and change status
      if (type === "agentSignature") {
        // alert(agreementId);
        // Ta fram lista på bookingids från agreementId
        let bookingids = [];
        const querySnapshot = await getDocs(query(collection(db, "Bookings"), where("agreementId", "==", agreementId)));
        querySnapshot.forEach((doc) => {
          bookingids.push(doc.data().bookingid);
        });

        // mappa genom bookingids och spara ny status och agentSignature
        await bookingids.map(async (item) => {
          let agentSignature = await Signature;
          let status = "Confirmed";
          console.log("updating booking:", item, agentSignature);
          await updateDoc(doc(db, "Bookings", item), {
            agentSignature: agentSignature,
            status: status,
            updated: Timestamp.fromDate(new Date()),
          });
          console.log("updating Agreement:", item, agentSignature);
          await updateDoc(doc(db, "Agreements", agreementId), {
            agentSignature: agentSignature,
            status: status,
            bookingids: bookingids,
          });
        });

        // alert("AgreementId: " + agreementId + " Type: " + type);

        // if (document.querySelector("#agreement")) await archivePDF();

        // navigate("/Requests");
      }

      // OLD
      //If authorization is agentSignature store signature in booking and change status
      // if (type === "agentSignature") {
      //   currentBooking.agentSignature = await Signature;
      //   currentBooking.status = "Confirmed";
      //   await updateDoc(doc(db, "Bookings", bookingId), {
      //     ...currentBooking,
      //   });
      //   navigate("/Requests");
      // }
      // console.log(currentBooking);
      // navigate("/Requests");

      if (type === "signup") {
        navigate("/Signup");
      }
    }

    getData();
  }, []);

  useEffect(() => {
    if (doneArchiving) return;
    // Function to check for the existence of the element
    const checkForAgreementElement = () => {
      if (document.querySelector("#agreement")) {
        console.log("#agreement element has been added to the DOM!");
        if (doneArchiving) return;
        archivePDF();
        setDoneArchiving(true);
        // Perform any additional actions here
      }
    };
    // Check initially when component mounts
    if (!doneArchiving) checkForAgreementElement();
    // Set an interval to check periodically (e.g., every 500ms)
    const intervalId = setInterval(checkForAgreementElement, 500);
    // Cleanup interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, [!doneArchiving]);

  useEffect(() => {
    if (!doneArchiving) return;
    setQuickOpenClose(false);
  }, [doneArchiving]);

  // Make sure email got sent before redirecting
  useEffect(() => {
    console.log("sentArray", sentArray);
    // If email was sent, redirect
    type === "venueSignature" && setDoneArchiving(true);
    if (sentArray[0] === agreementId + type && doneArchiving) {
      // alert(agreementId + type);
      type === "venueSignature" && navigate("/Owner/Home");
      // type === "agentSignature" && navigate("/Requests");
      type === "agentSignature" && navigate("/Home");
    }
    if (sentArray[0] === "ERROR") {
      alert("❌ Something went wrong. Please try again.");
      setTimeout(() => {
        navigate("/Owner/Home");
      }, 3000);
    }
  }, [sentArray, doneArchiving]);

  async function archivePDF() {
    if (type !== "agentSignature") return; // Only archive when "agent" has signed. (Don't archive when "venue" signs)
    const timeStamp = new Date().toISOString();
    console.log("Archiving...");
    console.log("agreement", agreement);
    const htmlData = document.querySelector("#agreement") ? document.querySelector("#agreement").innerHTML : "<h1>ERROR</h1>";
    console.log("htmlData", htmlData);
    const body = {
      htmlData,
      filename: "Avtal" + agreementId,
      agreementId: agreementId,
      venueId: agreement && agreement.venueid,
      venue: agreement && agreement.venue.venue,
      venueEmail: agreement && agreement.venueSignature.userId,
      agentEmail: userId,
      venueSocialId: agreement && agreement.venueSignature.socialId,
      agentSocialId: socialId,
      venuePhoneNumber: agreement && agreement.venueSignature.phoneNumber,
      agentPhoneNumber: phoneNumber,
      timeStamp,
    };
    console.log("body", body);

    const response = await axios.post("https://europe-west3-venuehub-64e72.cloudfunctions.net/generatePdfForArchive", body);
    // const response = await axios.post(
    //   "http://127.0.0.1:5001/venuehub-64e72/europe-west3/generatePdfForArchive",
    //   body // Important: Set the response type to 'blob' for PDF download
    // );
    console.log("done...", response.data);
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Grid container sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <Grid item>
          {type === "agentSignature" && (
            <div>
              <BatchAgreementModal agreementId={agreementId} open={quickOpenClose} sign="archiving" />
            </div>
          )}
          <Typography variant="h4" align="center" gutterBottom>
            Thank You
          </Typography>
          {/* <div>User ID: {userId}</div>
          <div>Type: {type}</div>
          <div>Booking ID: {bookingId}</div>
          <div>Return Address: {returnAddress}</div> */}
          Authorization complete.
        </Grid>
        <Grid item>
          <CircularProgress />
        </Grid>

        <Grid item>
          {(type === "venueSignature" || type === "agentSignature") && (
            <div style={{ width: "50vw", textAlign: "center" }}>
              Finalizing... please wait to be redirected.
              <EmailProgressBar docId={agreementId + type} sentArray={sentArray} setSentArray={setSentArray} />
            </div>
          )}
          {/* <h2>Please Wait</h2> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default PassedVerification;
