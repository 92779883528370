/** @format */

import { Avatar, Box, Button, Divider, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Select, Tooltip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { collection, doc, getDoc, getDocs, onSnapshot, query, Timestamp, where } from "firebase/firestore";
import { db, getBookingsByVenueid } from "../firebase";

import { CircularProgress } from "@mui/material";
// import { listenToOwnedVenuesBookings, reloadCalendarBookings } from "./Listeners";
import DisplayObject from "./displayObject";
import { Link, Navigate, useNavigate } from "react-router-dom";
import LoadingDots from "../components/UI helpers/LoadingDots";
import AddVenue from "../components/Forms/AddVenue";
import EditIcon from "@mui/icons-material/Edit";
import shortText from "./shortText";
import {
  storeAccessibleVenues,
  storeCurrentBookings,
  storeCurrentLoadingBookingsProgress,
  storeCurrentSettings,
  storeCurrentTicketSummary,
  storeCurrentVenue,
  storeCurrentVenueBookings,
} from "../features/venueFilter/venueFilterSlice";
import ObjectSize from "./ObjectSize";
import { set } from "date-fns";
import { updateAccessibleVenuesWhenCurrentVenue } from "../components/MiscUIComponents/updateAccessibleVenuesWhenCurrentVenue";
import {
  deleteDatabase,
  ensureStores,
  fetchCachedData,
  openDatabase,
  storeDataInIndexedDB,
} from "../components/Sidebar/IndexedDBForCurrentVenueBookings";
import { cache } from "react";
import CacheControl from "./CacheControl";

const SelectVenue = () => {
  const cs = useSelector((state) => state.counter.currentSettings);
  console.log("cs", cs);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentTicketSummary = useSelector((state) => state.counter.currentTicketSummary);
  const superOwner = useSelector((state) => state.counter.superOwner);
  const venueAccess = useSelector((state) => state.counter.venueAccess);

  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const [loading, setLoading] = useState(false);
  const [loadingBookings, setLoadingBookings] = useState(0);
  const [value, setValue] = useState(cs.svVenueid ? cs.svVenueid : null);
  const [accessibleVenues, setAccessibleVenues] = useState(["none"]);
  const [currentVenueid, setcurrentVenueid] = useState();
  const [groupedData, setGroupedData] = useState({});
  const [fetching, setFetching] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const currentVenueBookings = useSelector((state) => state.counter.currentVenueBookings);
  const [cached, setCached] = useState([]);
  const [online, setOnline] = useState(false);
  const [bookingsListenerIsSet, setBookingsListenerIsSet] = useState(false);
  const [venueListenerIsSet, setVenueListenerIsSet] = useState(false);
  const [accessibleVenuesIsSet, setAccessibleVenuesIsSet] = useState(false);
  const [venueIsSet, setVenueIsSet] = useState(false);

  // The bookingslistener doesn't update the ordinary currentVenue, so we're updating the currentVenueRef whenever currentVenue changes.
  const currentVenueRef = useRef(currentVenue);

  useEffect(() => {
    currentVenueRef.current = currentVenue;
  }, [currentVenue]);

  async function handleChange(venueid) {
    setOnline(false);
    // Clear all bookings and currentVenue
    dispatch(storeCurrentVenueBookings([]));
    // dispatch(storeCurrentVenue(null));

    if (venueid === undefined || !venueid) return;
    setValue(venueid);
    setBookingsListenerIsSet(false);
    setVenueListenerIsSet(false);
    // Set the current venue to the selected venue
    const venue = accessibleVenues.find((venue) => venue.venueid === venueid);
    dispatch(storeCurrentVenue(venue));
    // storeDataInIndexedDB(dbName, data, storeName, keyPath)
    await storeDataInIndexedDB("venuehubSettings", [{ venueid: venueid, lastUsed: "lastUsed" }], "venueid", "lastUsed"); // Store the timestamp showing when bookings last were cached in indexedDB.
    // listenToVenueBookings(venue);

    // dispatch(storeCurrentSettings({ ...cs, svVenueid: venueid }));
  }

  useEffect(() => {
    if (value === "none") return;
    if (bookingsListenerIsSet) return;
    // if (venueListenerIsSet) return;
    listenToVenue(currentVenue);
    listenToVenueBookings(currentVenue);
  }, [value]);

  const handleDeleteDatabase = async () => {
    alert("Clearing cache");
    await deleteDatabase("venuehubDatabase");
    await deleteDatabase("venuehubTimestamp");
    await deleteDatabase("venuehubSettings");
  };

  function listenToVenue(currentVenue) {
    console.log("listen to new venue");
    if (currentVenue.venueid === "none" || currentVenue.venueid === undefined) return;
    const unsubscribeVenue = onSnapshot(
      query(collection(db, "Venues"), where("venueid", "==", currentVenue.venueid)),
      async (querySnapshot) => {
        console.time("venue time");
        // setLoading(true);
        // setOnline(false);
        // Only process changes, additions, and deletions
        querySnapshot.docChanges().forEach(async (change, index) => {
          console.log("indexad", index);
          if (change.type === "added") {
            console.log("Document added: ", change.doc.data());
            // Handle added document
          } else if (change.type === "modified") {
            console.log("Document modified: ", change.doc.data());
            // Handle modified document
          } else if (change.type === "removed") {
            console.log("Document removed: ", change.doc.id);
            // Handle removed document
          }
        });
        console.log("🚘🚘 🚘🚘 🚘🚘  loaded updated venue");
        dispatch(storeCurrentVenue(querySnapshot.docs[0].data()));
        setVenueListenerIsSet(true);
        // change accessible Venues
        let tmpAccessibleVenues = accessibleVenues.map(
          (venue) =>
            venue.venueid === currentVenue.venueid
              ? { ...querySnapshot.docs[0].data() } // Update the target venue
              : venue // Leave other venues unchanged
        );
        setVenueListenerIsSet(true);
        setAccessibleVenues(tmpAccessibleVenues);
        console.timeEnd("venue time"); // Measure time to initiate the snapshot listener
        // loadVenues(venueAccess);
        console.log("currentVenue changed");
        // setLoading(false);
        // setOnline(true);
      }
    );
  }

  // function listenToVenueBookings(currentVenue) {
  async function listenToVenueBookings() {
    setWaiting(true);
    if (currentVenue.venueid === "none" || currentVenue.venueid === undefined) return;

    setListener(); // Uncomment this
    console.time("bookings time 4 sure");
    setLoading(true);
    let tempCurrentVenue = [];

    async function setListener() {
      console.time("setListener"); // Start timing the entire function
      if (currentVenue.venueid === undefined) return;
      if (currentVenue && currentVenue.venueid) {
        // First get cached bookings
        const getChachedBookings = await fetchCachedData("venuehubDatabase", currentVenue.venueid, "bookingid");
        const getTimestampChachedBookings = await fetchCachedData("venuehubTimestamp", currentVenue.venueid, "venueid");
        const cachedTimestamp =
          // getTimestampChachedBookings[0] && getTimestampChachedBookings[0].timestamp;
          getTimestampChachedBookings[0] && getTimestampChachedBookings[0].dateTime;

        const cachedBookings = getChachedBookings.map((booking) => {
          // Remove products and features from cached bookings
          return {
            ...booking, // Spread the existing booking properties
            venue: {
              ...booking.venue, // Spread the existing venue properties
              products: [], // Replace products with an empty array
              features: [], // Replace features with an empty array
            },
          };
        });
        if (cachedBookings.length > 0) {
          setCached(cachedBookings);
          // The bookingslistener might be using an outdated currentVenue since it was first set, so we're updating the currentVenueRef whenever currentVenue changes.
          tempCurrentVenue = { ...currentVenueRef.current, bookings: cachedBookings };
          // dispatch(storeCurrentVenue(tempCurrentVenue));
          // dispatch(storeCurrentVenueBookings(cachedBookings));
        }

        // THIS IS THE NEW LISTENER That only listens to changes.
        // console.log("cachedTimestamp", cachedTimestamp, new Date(cachedTimestamp.seconds));

        // const q =
        //   cachedTimestamp !== undefined
        //     ? query(collection(db, "Bookings"), where("venueid", "==", currentVenue.venueid), where("updated", ">", cachedTimestamp))
        //     : query(collection(db, "Bookings"), where("venueid", "==", currentVenue.venueid));
        const td = new Date();
        // td.setHours(0, 0, 0, 0);
        const todayTimestamp = Timestamp.fromDate(td);
        console.log("todayTimestamp", todayTimestamp.toDate());
        const q =
          cachedTimestamp === undefined
            ? query(collection(db, "Bookings"), where("venueid", "==", currentVenue.venueid))
            : query(collection(db, "Bookings"), where("venueid", "==", currentVenue.venueid), where("updated", ">", cachedTimestamp));

        const unsubscribe = onSnapshot(q, async (querySnapshot) => {
          console.time("onSnapshot"); // Measure time to initiate the snapshot listener
          // Only process changes, additions, and deletions
          querySnapshot.docChanges().forEach(async (change, index) => {
            console.log("indexad", index);
            if (change.type === "added") {
              console.log("Document added: ", change.doc.data());
              // Handle added document
            } else if (change.type === "modified") {
              console.log("Document modified: ", change.doc.data());
              // Handle modified document
            } else if (change.type === "removed") {
              console.log("Document removed: ", change.doc.id);
              // Handle removed document
            }
          });

          // Optionally, maintain a local cache to avoid redundant reprocessing
          const updatedBookings = querySnapshot.docs.map((doc) => doc.data());
          updatedBookings.sort((a, b) => new Date(a.date) - new Date(b.date));

          const minimizedBookings = updatedBookings.map((booking) => ({
            ...booking,
            venue: {
              ...booking.venue,
              products: [],
              features: [],
            },
          }));
          // alert(currentVenue.venue);
          // Assuming minimizedBookings is the array of new bookings from Firestore
          // and cachedBookings is an array you've retrieved from IndexedDB or another local source

          // Combine both arrays
          const combinedBookings = [...cachedBookings, ...minimizedBookings];

          // Use a Map to remove duplicates (last one wins)
          const bookingsMap = new Map();
          combinedBookings.forEach((booking) => {
            bookingsMap.set(booking.bookingid, booking);
          });

          // Convert the Map values back to an array
          const uniqueBookings = Array.from(bookingsMap.values());

          // Now update tempCurrentVenue with the unique bookings
          tempCurrentVenue = { ...currentVenueRef.current, bookings: uniqueBookings };
          // The bookingslistener might be using an outdated currentVenue since it was first set, so we're updating the currentVenueRef whenever currentVenue changes.

          // tempCurrentVenue = { ...currentVenue, bookings: minimizedBookings };
          setBookingsListenerIsSet(true);
          setLoadingBookings(100);

          // create timeStamp to show when the indexedDb was last updated
          // const timestamp = [
          //   { timestamp: Timestamp.fromDate(new Date()), venueid: currentVenue.venueid, dateTime: new Date().toISOString() },
          // ];
          const timestamp = [{ timestamp: todayTimestamp, venueid: currentVenue.venueid, dateTime: todayTimestamp.toDate() }];
          // Dispatch and store minimized bookings
          await dispatchToStore(tempCurrentVenue);
          await storeDataInIndexedDB("venuehubDatabase", minimizedBookings, currentVenue.venueid, "bookingid"); // Store the bookings in indexedDB
          await storeDataInIndexedDB("venuehubTimestamp", timestamp, currentVenue.venueid, "venueid"); // Store the timestamp showing when bookings last were cached in indexedDB.
          console.timeEnd("onSnapshot"); // Measure time to initiate the snapshot listener
          // unsubscribe();
        });
      }
      setLoading(false);
      console.timeEnd("setListener"); // End timing the entire function
    }

    setWaiting(false);
  }

  async function dispatchToStore(tempCurrentVenue) {
    console.log("tempCurrentVenue X", tempCurrentVenue);
    const tmpFiltered = tempCurrentVenue.bookings.filter((item) => item.status !== "Killed");
    const tmpSmaller = tempCurrentVenue.bookings.map((b) => ({
      ...b,
      // venue: undefined,
      // venue: b.venue ? { ...b.venue, products: undefined } : b.venue,
    }));
    // dispatch(storeCurrentVenueBookings(tempCurrentVenue.bookings));
    dispatch(storeCurrentVenueBookings(tmpSmaller));
    dispatch(storeCurrentVenue(tempCurrentVenue)); // With all bookings inside currentVenue.bookings // <=== HERE
    // dispatch(storeCurrentVenue(currentVenue)); // With no bookings inside currentVenue.bookings
    setOnline(true);
  }

  async function loadVenues(venueAccess) {
    ensureStores("venuehubDatabase", venueAccess, "bookingid"); // Ensure the database is opened before fetching data
    ensureStores("venuehubTimestamp", venueAccess, "venueid");
    ensureStores("venuehubSettings", ["venueid"], "lastUsed");
    // await ensureStores("venuehubTimestamp", venueAccess, "venueid");
    let tmpAccessibleVenues = [];
    try {
      // Loop through each venueid in the venueAccess array
      for (const venueid of venueAccess) {
        // Fetch each document from the "Venues" collection based on the venueid
        const venueDoc = await getDoc(doc(db, "Venues", venueid));
        // Check if the document exists
        if (venueDoc.exists()) {
          // Push the venue data into the accessibleVenues array
          tmpAccessibleVenues.push(venueDoc.data());
        } else {
          console.error(`No venue found for venueid: ${venueid}`);
        }
      }
      // Return or set accessibleVenues as needed
      // console.log("Accessible Venues:", accessibleVenues);
      setAccessibleVenues(tmpAccessibleVenues);
      dispatch(storeAccessibleVenues(tmpAccessibleVenues));
      setAccessibleVenuesIsSet(true);
      // setAccessibleVenuesIsSet(true);
      console.timeEnd("getVenues");
      setFetching(false);
      return tmpAccessibleVenues; // Return or use accessibleVenues accordingly
    } catch (error) {
      console.error("Error loading venues:", error);
    }
  }

  useEffect(() => {
    if (venueAccess[0] === "none") return;
    setFetching(true);

    // Call the loadVenues function with the venueAccess array
    loadVenues(venueAccess);
  }, [venueAccess]);

  // When accessible Venues are loaded, set the currentVenue to the last venue in the array
  // useEffect(() => {
  //   // if (accessibleVenuesIsSet) return;
  //   console.log("running accessibleVenues");
  //   if (Object.keys(accessibleVenues[0]).length === 0 && accessibleVenues[0].constructor === Object) return; // If accessibleVenues is empty, return
  //   handleChange(accessibleVenues[0] && accessibleVenues[accessibleVenues.length - 1].venueid);
  //   // dispatch(storeCurrentVenue(accessibleVenues[0] && accessibleVenues[accessibleVenues.length - 1]));
  //   dispatch(storeAccessibleVenues(accessibleVenues));
  // }, [accessibleVenues]);

  useEffect(() => {
    console.log("currentVenue again");
    if (currentVenue && currentVenue.venueid === "none") return;

    // const updatedAccessibleVenues = updateAccessibleVenuesWhenCurrentVenue(accessibleVenues, currentVenue);
    // // setAccessibleVenues(updatedAccessibleVenues);

    if (Object.keys(currentVenue).length === 0 && currentVenue.constructor === Object) return;
    console.log("currentVenuetickets", currentVenue);
    if (!currentVenue) {
      alert("No currentVenue");
      return;
    }

    const ticketVenueId = currentVenue.ticketVenueId && currentVenue.ticketVenueId;
    if (!ticketVenueId) return;
    // alert("ticketVenueId: " + ticketVenueId);
    console.log("fetching ticket summary for", ticketVenueId);
    fetchTicketSummary(ticketVenueId);

    async function fetchTicketSummary(ticketVenueId) {
      console.time("fetchTickets");
      try {
        const ticketSummaryRef = doc(db, "TicketSummaries", ticketVenueId);
        const ticketSummarySnap = await getDoc(ticketSummaryRef);

        if (!ticketSummarySnap.exists()) {
          console.warn("No ticket summary found for this venue");
          return;
        }

        console.log("Ticket Document data:", ticketSummarySnap.data());
        const ticketSummaryData = ticketSummarySnap.data();
        dispatch(storeCurrentTicketSummary(ticketSummaryData));
      } catch (error) {
        console.error("Error fetching ticket summary:", error);
      } finally {
      }
    }
    console.time("fetchTickets");
    // }, [currentVenue]);
  }, [currentVenue.venueid]);

  // REMOVE?
  // useEffect(() => {
  //   if (Object.keys(currentTicketSummary[0]).length === 0 && currentTicketSummary[0].constructor === Object) return;
  //   currentTicketSummary.length > 0 && console.log("currentTicketSummary:", currentTicketSummary);
  // }, [currentTicketSummary]);

  useEffect(() => {
    if (!online) console.time("Now"); // Measure time to initiate the snapshot listener
    if (online) console.timeEnd("Now"); // Measure time to initiate the snapshot listener
  }, [online]);

  useEffect(() => {
    console.log("loading", loading);
  }, [loading]);

  // Only run once when accessibleVenues are loaded the first time
  useEffect(() => {
    if (accessibleVenues[0] === "none") return;
    if (venueIsSet) return;
    getChachedLastUsedVenueid();
    async function getChachedLastUsedVenueid() {
      const data = await fetchCachedData("venuehubSettings", "venueid", "lastUsed");
      console.log("data", data);

      // If lastUsed venue isn't found among accessibleVenues, set the first venue in the accessibleVenues array
      if (data.length > 0) {
        const lastUsedVenueid = data[0].venueid;
        const lastUsedVenue = accessibleVenues.find((venue) => venue.venueid === lastUsedVenueid);
        if (!lastUsedVenue) {
          setVenueIsSet(true);
          handleChange(accessibleVenues[0].venueid);
          return;
        }
      }
      // If no lastUsed venue is found, set the first venue in the accessibleVenues array
      if (data.length === 0) {
        setVenueIsSet(true);
        handleChange(accessibleVenues[0].venueid);
      }
      // Otherwise use the lastUsed venue
      if (data.length > 0) {
        const lastUsedVenueid = data[0].venueid;
        setVenueIsSet(true);
        handleChange(lastUsedVenueid);
      }
    }
  }, [accessibleVenues]);

  return (
    <div>
      <div>
        {superOwner && (
          <>
            cache size: <ObjectSize dataObject={cached} />
            <Button onClick={() => handleDeleteDatabase()} size="small" variant="outlined">
              Clear Cache
            </Button>
          </>
        )}
      </div>

      <div className="bottomLoading">
        {fetching && <>fetching</>}
        {waiting && <>waiting</>}
        {loadingBookings > 0 && loadingBookings < 100 && (
          <LinearProgress variant="determinate" value={loadingBookings} style={{ opacity: "0.3" }} />
        )}
      </div>
      <Box sx={{ minWidth: 100, maxWidth: 200, height: 20 }} mt={10}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label" style={{ marginTop: "-25px" }} shrink={true}>
            {!loading ? (
              <>Select Venue</>
            ) : (
              <>
                {/* <CircularProgress size={15} />  */}
                Loading venues
                <LoadingDots speed="300" />
              </>
            )}
          </InputLabel>
          {console.log("VALUE:", currentVenue)}

          {/* <span
            className="onlineIndicator"
            style={{ width: "10px", height: "10px", background: online ? "green" : "orange", borderRadius: "50%" }}
          ></span> */}
          <span
            className="onlineIndicator"
            style={{ width: "10px", height: "10px", background: online ? "green" : "orange", borderRadius: "50%" }}
          >
            <CacheControl />
          </span>

          {/* <Tooltip title={loading ? "Wait until bookings finished loading" : "Select Venue"} placement="top"> */}
          <Select
            value={value}
            // renderValue={shortText(value, 10)}
            className="selectVenue"
            labelId="select-venue"
            id="select-venue"
            onChange={(e) => handleChange(e.target.value)}
            fullWidth={true}
            sx={{ height: "30px", color: "transparent" }}
            // disabled={!online}
          >
            {loading && (
              <MenuItem value={null} name="Loading venues">
                {" "}
                <CircularProgress size={15} />{" "}
              </MenuItem>
            )}
            {accessibleVenues.map((venue) => (
              <MenuItem value={venue.venueid} name={venue.venue}>
                <Avatar
                  size="small"
                  alt={venue.venue}
                  src={venue.venueImage}
                  sx={{
                    marginRight: "10px",
                    float: "left",
                    border: "1px solid",
                  }}
                />
                {(loading || !online || loadingBookings < 100) && (
                  <CircularProgress
                    size={43}
                    sx={{
                      position: "absolute",
                      top: "-42%",
                      left: "6.85%",
                      transform: "translate(-50%, -50%)",
                      zIndex: -1, // Ensure it's behind the avatar if needed
                      opacity: "0.3",
                    }}
                  />
                )}
                <p style={{ color: "white", display: "flex", marginTop: "15px" }}>{venue.venue && shortText(venue.venue, 16)}</p>
              </MenuItem>
            ))}

            <Divider />
            <MenuItem p={2}>
              <AddVenue location="selectVenue" />
            </MenuItem>
          </Select>
          {/* </Tooltip> */}
        </FormControl>
      </Box>
    </div>
  );
};

export default SelectVenue;
