/** @format */

import { Button, CircularProgress, Grid, Popper, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { set } from "date-fns";
import React, { useEffect, useState } from "react";
import SyncNortic from "../helpers/SyncNortic";
import { collection, doc, getDoc, getDocs, getFirestore, query, Timestamp, where } from "firebase/firestore";
import { db, getBookingsByVenueid } from "../firebase";
import VenueInvoice from "../finshark/VenueInvoice";
import Tmp_VenueInvoice from "../finshark/Tmp_VenueInvoice";
import SubscriptionInput from "../helpers/SubscriptionInput";
import Settler from "../components/Banking/Settler";
import axios from "axios";
import FinsharkTransactions from "../finshark/FinsharkTransactions";
import LedgerView, { createCreditPost, createDebitPost } from "./LedgerView";
import PreChargeStatement from "../finshark/PreChargeStatement";
import BatchAgreementModal from "../components/Documents/BatchAgreementModal";
import DataSheetPremises from "../components/VisualData/DataSheetPremises";
import { log } from "../helpers/log";
import GetTixlyEvents from "../features/tixly/GetTixlyEvents";
import { CreateAdditionalPaymentPost } from "../components/Banking/CreateAdditionalPaymentPost";
import { numberFormatted } from "../helpers/Numbers";
import SimpleSignup from "../helpers/simpleSignup";
import { MonthNavigator } from "../components/MiscUIComponents/MonthNavigator";
import MonthRangePicker from "../components/UI helpers/MonthRangePicker";
import PdfMaker from "../components/UI helpers/PdfMaker";
import PdfDownloader from "../components/Documents/PdfDownloader";
import ColdplayPopularityInSweden from "../helpers/SpotifyArtistPopularity";
import PaymentSlider from "../helpers/PaymentSlider";
import CancelationSlider from "../helpers/CancelationSlider";
// import PdfMaker from "../components/UI helpers/PdfMaker";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import OCRGenerator from "../helpers/OCRGenerator";
import { useSelector } from "react-redux";
import EPCQRCode from "../components/Banking/EPCQRCode";
import { generateOCR } from "../helpers/generateOCR";
import TicksterTest from "../components/MiscUIComponents/Testing/TicksterTest";
import SkapaQrKod from "../helpers/SkapaQrKod";
import Settlement from "../components/Documents/Settlement";
import EventTravelForm from "./EventTravelForm";
import ObjectSize from "../helpers/ObjectSize";
import LoadingDots from "../components/UI helpers/LoadingDots";
import getTicketEventIdFromBookingId from "../components/MiscUIComponents/getTicketEventIdFromBookingId";
import getAllTicketSalesFromAgreementId from "../components/MiscUIComponents/getAllTicketSalesFromAgreementId";
import DevTodoList from "../helpers/DevTodoList";
import { NumericFormat } from "react-number-format";
import InsTranslate from "../helpers/InsTranslate";
import PdfAgreementArchiver from "../components/Documents/PdfAgreementArchiver";
import CheckArchivedAgreements from "../helpers/CheckArchivedAgreements";
import TicketFinderTickster from "../features/tickster/TicketFinderTickster";
import ModulesBackup from "./ModulesBackup";
import { calculateDistances } from "./calculateDistances";
import AgreementAddendum from "../components/Documents/AgreementAddendum";
import CreateSubOrganizerNortic from "../features/nortic/CreateSubOrganizerNortic";
import CreateEventNortic from "../features/nortic/CreateEventNortic";
import CreateTicketCategoriesNortic from "../features/nortic/CreateTicketCategoriesNortic";
import NewAgreement from "../components/Documents/newAgreement";
import ShowTicketCategoriesNortic from "../features/nortic/ShowTicketCategoriesNortic";
import SetTicketCategoriesProductNortic from "../features/nortic/SetTicketCategoriesProductNortic";
import BookingsBackup from "./BookingsBackup";
import MailingListOutput from "./MailingListOutput";
import TravelReport from "../helpers/TravelReport";
import SelectVenue from "../helpers/SelectVenue";
import { calculateBookingPrice } from "../components/MiscUIComponents/calculateBookingPrice";
import BasicHtmlEditor from "../helpers/BasicHtml";
import { helperText } from "../helpers/helperText";
import MouseTracker from "../features/Trackers/MouseTracker";
import { Mouse } from "@mui/icons-material";
import MouseDisplay from "../features/Trackers/MouseDisplay";
import ArchivedAgreementsList from "../helpers/ArchivedAgreements";
import AddUpdatedToAllBookingsOnce from "../helpers/AddUpdatedToAllBookingsOnce";
import HelpEditor from "../helpers/HelpEditor";
import HelpBox from "../helpers/HelpBox";
// import calculateDistances from "../helpers/calculateDistances";

const TestSite = () => {
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const superOwner = useSelector((state) => state.counter.superOwner);
  const userRole = useSelector((state) => state.counter.userRole);
  // const navigate = useNavigate();
  const [access, setAccess] = useState(false);
  const [userData, setUserData] = useState(false);
  const [formDB, setFormDB] = useState([]);
  const [string, setString] = useState([]);
  const [venue, setVenue] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(false);

  const [events, setEvents] = useState([]);

  useEffect(() => {
    // console.log("DATA", calculateDistances());
    let categories = categorizeDistances(calculateDistances());
    console.log("Categories", categories);
  }, []);

  function categorizeDistances(data) {
    const categories = {
      "15km or less": 0,
      "15-50km": 0,
      "50-500km": 0,
      "More than 500km": 0,
    };

    data.forEach((item) => {
      const distance = item.distance;
      const visitors = item.visitors;

      if (distance <= 15) {
        categories["15km or less"] += visitors;
      } else if (distance > 15 && distance <= 50) {
        categories["15-50km"] += visitors;
      } else if (distance > 50 && distance <= 500) {
        categories["50-500km"] += visitors;
      } else {
        categories["More than 500km"] += visitors;
      }
    });

    return categories;
  }

  // useEffect(() => {
  //   // load venue from "Venues" collection where venueid = currentVenue.venueid.
  //   async function getData() {
  //     const venueSnap = await getDoc(doc(db, "Venues", "wjrbwst2ttgcxv3s6ptyqphqqr2k7kk5"));
  //     console.log("Document data:", venueSnap.data());
  //     setVenue(venueSnap.data());
  //   }
  //   getData();
  // }, []);

  // useEffect(() => {
  //   if (userRole === "none") return;
  //   // load venue from "Venues" collection where venueid = currentVenue.venueid.
  //   async function getData() {
  //     setLoading(true);
  //     // const querySnapshot = await getDocs(query(collection(db, "Bookings"), where("venueid", "==", "wjrbwst2ttgcxv3s6ptyqphqqr2k7kk5")));
  //     // const querySnapshot = await getDocs(collection(db, "Venues"));
  //     const querySnapshot = await getDocs(query(collection(db, "Bookings"), where("venueid", "==", "yshmiexkxg5bipm5xh7uuyd3ot7pehjh")));
  //     querySnapshot.forEach((doc) => {
  //       // doc.data() is never undefined for query doc snapshots
  //       console.log(doc.id, " => ", doc.data());
  //     });
  //     console.log(
  //       "Booking data:",
  //       querySnapshot.docs.map((doc) => doc.data())
  //     );
  //     setLoading(false);
  //   }
  //   if (userRole === "owner") getData();
  // }, [userRole]);

  // async function fetchTickets() {
  //   try {
  //     const querySnapshot = await getDocs(collection(db, "temporary/eventid1234/tickets"));
  //     const tickets = [];
  //     querySnapshot.forEach((doc) => {
  //       tickets.push({ id: doc.id, ...doc.data() });
  //     });
  //     return tickets;
  //   } catch (error) {
  //     console.error("Error fetching tickets:", error);
  //     throw error;
  //   }
  // }

  // fetchTickets().then((tickets) => {
  //   console.log("Tickets:", tickets);
  // });

  // const d = new Date().toISOString().split("T")[0];
  // const d = new Date().toLocaleDateString("sv-SE");

  // CreateAdditionalPaymentPost({
  //   email: "marcus.dernulf@venuehub.live",
  //   amount: 10,
  //   due: "2024-01-01",
  //   nextDateToCheck: "2024-01-15",
  //   bookingids: "abc123",
  //   venue: "Maxime",
  //   referenceId: "Test123",
  // });
  // CreateAdditionalPaymentPost("marcus.dernulf@venuehub.live", 10, "2024-01-01", "2024-01-15", "abc123", "Maxime", "Test123");
  // CreateAdditionalPaymentPost(email, amount, due, nextDateToCheck, bookingids, venue, referenceId);

  // THIIS!!
  // userRole != "owner" ? navigate("/Home") : console.log("Access granted");
  // if (userRole == "none") navigate("/Home");

  // async function getTransactions() {
  //   console.log("getting transactions...");
  //   const body = { email: "marcus.dernulf@venuehub.live" };
  //   const response = await axios.post(`https://europe-west3-venuehub-64e72.cloudfunctions.net/finsharkGetAllTransactions`, body);
  //   console.log(response.data);
  // }

  async function trigger() {
    console.log("Starting...");
    const body = { email: "marcus.dernulf@venuehub.live" };
    // const response = await axios.post(`https://europe-west3-venuehub-64e72.cloudfunctions.net/checker`, body);  //for live
    const response = await axios.post(`http://127.0.0.1:5001/venuehub-64e72/europe-west3/checker`, body); // for testing

    console.log("💛 💛 💛", response.data);
  }

  async function triggerTickster() {
    console.log("testing events by venue...");
    const body = {
      venue: currentVenue.venue,
      organizer: "DCHMLTF6FCWGXMP", // maxim = DCHMLTF6FCWGXMP
      venueApiKey: "8fd52faea08489dce1a58a07040b22fa", // maxim = 8fd52faea08489dce1a58a07040b22fa
      venueid: currentVenue.venueid,
    };
    const response = await axios.post(`https://europe-west3-venuehub-64e72.cloudfunctions.net/getTicksterEventsByVenue`, body); //for live
    // const response = await axios.post(`http://127.0.0.1:5001/venuehub-64e72/europe-west3/getTicksterEventsByVenue`, body); // for testing
    setEvents(response.data);
    console.log("💚 💚 💚", response.data);
  }

  async function getTicksterTicketsByEvents() {
    console.log("getting tickets... (by event)");
    const body = {
      organizer: "DCHMLTF6FCWGXMP", // maxim = DCHMLTF6FCWGXMP
      venueApiKey: "8fd52faea08489dce1a58a07040b22fa", // maxim = 8fd52faea08489dce1a58a07040b22fa
    };
    // const response = await axios.post(`https://europe-west3-venuehub-64e72.cloudfunctions.net/getTicksterTicketsByEvent`, body); //for live
    const response = await axios.post(`http://127.0.0.1:5001/venuehub-64e72/europe-west3/getTicksterTicketsByEvent`, body); // for testing Tickets
    console.log("💚 ❤️ 💚", response.data);
  }

  async function triggerTicksterTickets() {
    console.log("testing tickets by venue...");
    const body = {
      // ticketVenueId: "jadwwwhevyyr058", // debaser = jadwwwhevyyr058
      // venueApiKey: "62dbc821f0c4d5181a49f4f00cb257f2", // debaseer = 62dbc821f0c4d5181a49f4f00cb257f2
      ticketVenueId: "DCHMLTF6FCWGXMP", // maxim = DCHMLTF6FCWGXMP
      venueApiKey: "8fd52faea08489dce1a58a07040b22fa", // maxim = 8fd52faea08489dce1a58a07040b22fa
    };
    const response = await axios.post(`https://europe-west3-venuehub-64e72.cloudfunctions.net/getTicksterTicketsByVenue`, body); //for live
    // const response = await axios.post(`http://127.0.0.1:5001/venuehub-64e72/europe-west3/getTicksterTicketsByVenue`, body); // for testing Tickets
    console.log("💚 ❤️ 💚", response.data);
  }

  async function summarizeTickets() {
    console.log("Summarizing tickets...");
    const body = {
      ticketVenueId: "DCHMLTF6FCWGXMP", // maxim = DCHMLTF6FCWGXMP
      venueApiKey: "8fd52faea08489dce1a58a07040b22fa", // maxim = 8fd52faea08489dce1a58a07040b22fa
    };
    const response = await axios.post(`https://europe-west3-venuehub-64e72.cloudfunctions.net/summarizeTickster`, body); //for live
    // const response = await axios.post(`http://127.0.0.1:5001/venuehub-64e72/europe-west3/summarizeTickster`, body); // for testing Tickets
    console.log("SuMmariZed 💚 ❤️ 💚", response.data);
  }

  async function triggerEncrypt() {
    const body = { string: string };
    const response = await axios.post(`https://europe-west3-venuehub-64e72.cloudfunctions.net/encryptString`, body); //for live
    // const response = await axios.post(`http://127.0.0.1:5001/venuehub-64e72/europe-west3/encryptString`, body); // for testing
    console.log("❤️ ❤️ ❤️", response.data);
    setString(response.data.encryptedString);
  }

  async function triggerDecrypt() {
    const body = { string: string };
    const response = await axios.post(`https://europe-west3-venuehub-64e72.cloudfunctions.net/decryptString`, body); //for live
    // const response = await axios.post(`http://127.0.0.1:5001/venuehub-64e72/europe-west3/decryptString`, body); // for testing
    console.log("❤️ ❤️ ❤️", response.data);
    setString(response.data.decryptedString);
  }

  async function generateQR() {
    console.log("Generating QR code...");
    const response = await axios.post(
      `https://europe-west3-venuehub-64e72.cloudfunctions.net/generateAndUploadQRCode?ocr=8fd52faea08489dce1a58a07040b22fa&&amount=100&&referenceId=upfront2342334jk432`
    );
    // const response = await axios.post(
    //   `http://127.0.0.1:5001/venuehub-64e72/europe-west3/generateAndUploadQRCode?ocr=8fd52faea08489dce1a58a07040b22fa&&amount=100&&referenceId=upfront2342334jk432`
    // ); //for testing
    console.log("QR code URL:", response.data);
  }

  async function getFees() {
    console.log("getting Fees...");
    const response = await axios.post(`https://europe-west3-venuehub-64e72.cloudfunctions.net/getAdditionalFees`);
    // const response = await axios.post(`http://127.0.0.1:5001/venuehub-64e72/europe-west3/getAdditionalFees`); //for testing
    console.log("getFees:", response.data);
  }

  async function archivePDF() {
    console.log("Archiving...");
    const pdfDoc = `<h1>Test</h1>`;

    const htmlData = `<table>
    <tr>
    <th>Company</th>
    <th>Contact</th>
    <th>Country</th>
    </tr>
    <tr>
    <td>Alfreds Futterkiste</td>
    <td>Maria Anders</td>
    <td>Germany</td>
    </tr>
    <tr>
    <td>Centro comercial Moctezuma</td>
    <td>Francisco Chang</td>
    <td>Mexico</td>
    </tr>
    <tr>
    
    </table>`;
    const body = {
      htmlData,
      filename: "test",
      agreementId: "1234-567",
      venueId: "7654-321",
      venueEmail: "agent@venuehub.live",
      agentEmail: "marcus.dernulf@venuehub.live",
    };
    // const response = await axios.post(
    //   "http://127.0.0.1:5001/venuehub-64e72/europe-west3/generatePdfForArchive",
    //   body // Important: Set the response type to 'blob' for PDF download
    // );
    const response = await axios.post("https://europe-west3-venuehub-64e72.cloudfunctions.net/generatePdfForArchive", body);
    console.log("done...", response.data);
  }

  // async function caller() {
  //   const response = await axios.post(`https://europe-west3-venuehub-64e72.cloudfunctions.net/callAnotherFunction`);
  //   console.log("Caller", response.data);
  // }

  // async function fetchData(eventId) {
  //   try {
  //     log("test", eventId);
  //     // URL of the API
  //     const url = `https://europe-west3-venuehub-64e72.cloudfunctions.net/getTixlyEventTicketSummary?eventId=${eventId}`;

  //     // Fetch the data
  //     const response = await fetch(url);
  //     console.log(response);
  //     // Check if the response is okay (status 200-299)
  //     if (!response.ok) {
  //       throw new Error(`HTTP error! status: ${response.status}`);
  //     }

  //     // Parse the JSON response
  //     const data = await response.json();

  //     // Here, 'data' contains the response object
  //     console.log("data", data);
  //     return data;
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // }

  // Example usage
  // const eventId = 72138;
  // fetchData(eventId).then((data) => {
  //   if (data) {
  //     console.log("Fetched data:", data);
  //     // You can now use 'data' as needed
  //   }
  // });

  const [OCR, setOCR] = useState(generateOCR());
  const [newVal, setNewVal] = useState(0);

  // const testcalc = calculateBookingPrice(bookingData, "totalIncVat");

  function sumTravelData(data) {
    // Use Array.reduce to accumulate totals in an object
    return data.reduce((acc, item) => {
      // Iterate over each property in the current item
      for (const [key, value] of Object.entries(item)) {
        // Convert string values (with or without spaces) to numbers
        const numValue =
          typeof value === "string"
            ? parseFloat(value.replace(/\s/g, "")) // remove all spaces before parsing
            : value; // already a number

        // If the parse fails or it isn't numeric, default to 0
        const validNumber = isNaN(numValue) ? 0 : numValue;

        // Accumulate the value
        acc[key] = (acc[key] || 0) + validNumber;
      }
      return acc;
    }, {});
  }

  // Usage example:
  // const summedData = sumTravelData(traveltestdata);
  // console.log("summedData", summedData);

  // const updated = {
  //   seconds: 1738526194,
  //   nanoseconds: 491000000,
  // };
  // const commonTS = Timestamp.fromDate(new Date());
  // console.log("wichis bigger", updated < commonTS);

  return (
    <Grid container p={8} spacing={4} flexDirection="row" style={{ display: "flexWrap", gap: "10px", height: "100vh" }}>
      {/* <ArchivedAgreementsList /> */}
      {/* <HelpBox id="tjena" /> */}
      {/* <AddUpdatedToAllBookingsOnce /> */}

      {/* <MouseTracker />
      <MouseDisplay /> */}
      <Grid item style={{ width: "100%", padding: "1vh" }}>
        {/* <SelectVenue /> */}
        {/* Price:{testcalc} */}
        <TravelReport />
      </Grid>
      <MailingListOutput />
      {/* <BookingsBackup /> */}
      {/* <div
        style={{
          width: "100%",
          padding: "15px",
          background: "#00ffd411",
          dixplay: "flex",
          gap: "30px",
          flexDirection: "column",
          margin: "10px",
        }}
      >
        <NewAgreement agreementId={"iq3wv25sypdrysyhbuwlsvhdnu545ynb"} language={"svenska"} />
      </div>
      <AgreementAddendum agreementId={"z6rh3sgt4ilf5dnuyuq7k6twqsweehmy"} type="sign" />
      <div style={{ width: "100%", padding: "15px", background: "#00ffd411", dixplay: "flex", gap: "30px", flexDirection: "column" }}>
        <h4>Nortic stuff</h4>
        <div>
          <SetTicketCategoriesProductNortic />
          </div>
        <div>
          <ShowTicketCategoriesNortic />
          <CreateTicketCategoriesNortic />
        </div>
        <div>
          <CreateSubOrganizerNortic />
        </div>
        <div>
          <CreateEventNortic />
        </div>

        <div>
          <SyncNortic ticketOrganizerId={2915} ticketEventId={51819} />
        </div>
      </div> */}

      {/* <BatchAgreementModal agreementId={"lspxl45rcdydyaehuhynm7kxsfqpb37u"} sign="venueSignature" /> */}
      {/* <CheckArchivedAgreements /> */}
      {/* <InsTranslate phrase="Hello" from="en" to="sv" /> */}
      {/* <BatchAgreementModal agreementId="52qun3q5hvnr2y2eno5fiqnfyqd3sv63" open="true" /> */}
      {superOwner && (
        <>
          {/* <TextField label="test" value={NumericFormat} value={12345} thousandSeparator /> */}
          {/* <NumericFormat
            value={0}
            suffix={currentVenue.currency}
            thousandSeparator=" "
            customInput={TextField}
            onValueChange={(event) => {
              const { formattedValue, value } = event;
              setNewVal(value); // Use the unformatted 'value' property
            }} */}
          />
          {/* <TicketFinderTickster /> */}
          {/* <TextField label="test" value={newVal} /> */}
          {/* <Popper open={true}>
            <Box sx={{ border: 1, p: 1, bgcolor: "background.paper" }}>
              <CircularProgress />
            </Box>
          </Popper> */}
          {/* <DevTodoList /> */}
          {/* <ObjectSize dataObject={venue} /> */}
          {/* <div style={{ width: "100%", background: "red", height: "700px", padding: "20px", overflow: "scroll" }}>
            <ModulesBackup />
          </div> */}
          {/* User role: {userRole}
          {loading && <h1>Loading{<LoadingDots speed={300} />}</h1>} */}
          {/* <EventTravelForm /> */}
          {/* <Settlement bookingid="ityhjkzdw5g2fqkvi6qj2vvtkvwjtbke" /> */}
          {/* <SkapaQrKod /> */}
          {/* <CancelationSlider /> */}
          {/* <PaymentSlider /> */}
          {/* {OCR}
      <OCRGenerator /> */}
          {/* <TicksterTest /> */}
          {/* <h1>{d}</h1> */}
          {/* <EPCQRCode bg={"363-0274"} ocr={"1234 5678 2345 6789"} amount={123.45} /> */}
          {/* <ColdplayPopularityInSweden clientId="6cd07c087f234c9a9cc75a0d401352e6" /> */}
          {/* <PdfDownloader /> */}
          {/* <PdfMaker /> */}
          {/* <DataSheetPremises /> */}
          {/* <div style={{ background: "red", height: "100px", width: "200px" }}>
        // <BatchAgreementModal agreementId={"qzgl2sorhurne35lzyrct3q3iga2evtj"} sign="venueSignature" />
      </div> */}
          {/* <GetTixlyEvents /> */}
          {/* <PreChargeStatement /> */}
          <Grid item xs={12}>
            <MouseTracker />
            {/* <MonthRangePicker /> */}
            {/* <LedgerView /> */}
          </Grid>
          {/* <Grid item>
            <Button onClick={() => archivePDF()} color="secondary">
              Go archive som pdf
            </Button>
          </Grid> */}
          {/* <Grid item>
            <Grid item style={{ display: "flex", gap: "15px" }}>
              <Button onClick={() => trigger()} variant="outlined">
                Trigger Checker
              </Button>
              <Button onClick={() => triggerTickster()} variant="outlined">
                Tickster events
              </Button>
              <Button onClick={() => triggerTicksterTickets()} variant="outlined">
                Tickster tickets
              </Button>
              <Button onClick={() => getTicksterTicketsByEvents()} variant="outlined">
                Tickster tickets (by event)
              </Button>
              <Button onClick={() => summarizeTickets()} variant="outlined">
                Summarize
              </Button>
              <Button onClick={() => triggerEncrypt()} variant="outlined">
                Encrypt
              </Button>
              <Button onClick={() => triggerDecrypt()} variant="outlined">
                Decrypt
              </Button>
              <Button onClick={() => generateQR()} variant="outlined">
                Server side QR code
              </Button>
              <Button onClick={() => getFees()} variant="outlined">
                Get Fees
              </Button>
              <Button onClick={() => getBookings()} variant="outlined">
            getBookings
          </Button>
              <input type="text" value={string} onChange={(e) => setString(e.target.value)} />
              <Grid item>
            <Button onClick={() => caller()} variant="outlined">
              Caller test
            </Button>
          </Grid>
            </Grid>
          </Grid> */}
          {/* <Grid item mt={4}>
            <FinsharkTransactions />
          </Grid> */}
          <Grid item>
            {/* <Settler agreementId="6mekavlyn2eiinyyekidyvnoiwi7gqcu" type="signedAgreement" span={currentVenue.span} />{" "} */}
          </Grid>
          {/* THIS */}
          {/* <Settler agreementId="rvqpfnrmpemr65vammuplaz7azbaalts" type="signedAgreement" span="Monthly" /> */}
          {/* <Settler agreementId="eh6xfcqxnh6mpsourv3u3223fggoppig" type="signedAgreement" span="Monthly" /> */}
          {/* <Settler agreementId="gfs5fgig77whiqx6wwehuylaj5zazz6z" type="preCharge" /> */}
          {/* <SubscriptionInput /> */}
          {/* <Tmp_VenueInvoice
        bookingid={"bvi4pzz4zsqpjkbfowdksd57wnsov5r6"}
        // paymentButton={!booking.deductFromTickets && true}
        paymentButton={true}
        payoutButton={false}
        sendButton={true}
        // handleSend={handleSend}
        // close={handleCloseInvoice}
      /> */}
          {/* <SyncNortic ticketOrganizerId={2915} ticketEventId={51819} /> */}
        </>
      )}
    </Grid>
  );
};

export default TestSite;

// const bookingData = {
//   mdmq5rya: {
//     additional: "3 Personal Event",
//     type: "standardFields",
//     options: {
//       x7z2hqkz: {
//         optionId: "x7z2hqkz",
//         info: "",
//         option: "Kund har egna värdar på plats",
//         vat: "25%",
//         selected: true,
//         markUp: 0,
//         internalCost: 0,
//         price: "...",
//         quantity: "3",
//         CO2e: 0,
//         each: "...",
//         specs: "",
//       },
//       ebdggula: {
//         each: "550",
//         quantity: 1,
//         price: "550",
//         specs: "",
//         info: "timpris",
//         optionId: "ebdggula",
//         vat: "25%",
//         option: "Catering & servis",
//         CO2e: 0,
//         markUp: 0,
//         internalCost: "0",
//       },
//       gtjqg2mc: {
//         CO2e: 0,
//         specs: "",
//         option: "Bartenders",
//         each: "550",
//         vat: "25%",
//         internalCost: "300",
//         quantity: 1,
//         info: "timpris",
//         optionId: "gtjqg2mc",
//         markUp: 0,
//         price: "550",
//       },
//       ze45g74a: {
//         internalCost: 0,
//         CO2e: 0,
//         option: "Eventansvarig ingår",
//         optionId: "ze45g74a",
//         each: 0,
//         vat: "25%",
//         price: 0,
//         specs: "",
//         quantity: 1,
//         markUp: 0,
//         info: "(7 h)",
//       },
//       kukgq4gf: {
//         price: "1000",
//         option: "Venuansvarig",
//         quantity: 1,
//         CO2e: 0,
//         each: "1000",
//         vat: "25%",
//         optionId: "kukgq4gf",
//         info: "timpris",
//         specs: " ",
//       },
//       t4fepk5a: {
//         option: "Maxim personal, värdar/servis/bartenders",
//         selected: false,
//         CO2e: 0,
//         price: "18150",
//         quantity: 1,
//         optionId: "t4fepk5a",
//         internalCost: 0,
//         markUp: 0,
//         info: "",
//         each: "18150",
//         specs: "",
//         vat: "25%",
//       },
//       rtbrual3: {
//         specs: "",
//         quantity: 1,
//         CO2e: 0,
//         each: 0,
//         internalCost: 0,
//         price: 0,
//         optionId: "rtbrual3",
//         markUp: 0,
//         info: "",
//         vat: "25%",
//         option: "Rabatt eventpersonal",
//       },
//       "33pui7lm": {
//         info: "",
//         price: 0,
//         specs: "",
//         option: "Maxim värd/ar & bartender/s",
//         markUp: 0,
//         internalCost: 0,
//         optionId: "33pui7lm",
//         vat: "25%",
//         each: 0,
//         CO2e: 0,
//         quantity: 1,
//       },
//       a43pmj4d: {
//         selected: true,
//         markUp: 0,
//         CO2e: 0,
//         option: "Maximvärdar",
//         each: "550",
//         price: 9900,
//         quantity: "18",
//         vat: "25%",
//         internalCost: 0,
//         info: "timpris",
//         specs: "",
//         optionId: "a43pmj4d",
//       },
//     },
//     icon: {
//       ref: null,
//       key: null,
//       _owner: null,
//       type: {
//         type: {},
//         compare: null,
//       },
//       props: {
//         className: "categoryIcon",
//       },
//     },
//     additionalId: "mdmq5rya",
//   },
//   "56zeriiz": {
//     additional: "2 Teknik",
//     type: "standardFields",
//     additionalId: "56zeriiz",
//     icon: {
//       _owner: null,
//       type: {
//         type: {},
//         compare: null,
//       },
//       ref: null,
//       key: null,
//       props: {
//         className: "categoryIcon",
//       },
//     },
//     options: {
//       asgqphbn: {
//         price: "-17250",
//         quantity: 1,
//         info: "",
//         each: "-17250",
//         optionId: "asgqphbn",
//         vat: "25%",
//         internalCost: 0,
//         specs: "",
//         option: "Rabatt, teknik",
//         selected: true,
//         CO2e: 0,
//         markUp: 0,
//       },
//       okbwfk7w: {
//         option: "Kördator",
//         quantity: 1,
//         specs: "",
//         optionId: "okbwfk7w",
//         price: "2500",
//         info: "",
//         vat: "25%",
//         CO2e: 0,
//         each: "2500",
//       },
//       wbrfk6ip: {
//         option: "Följespot",
//         each: "4000",
//         price: "4000",
//         info: "",
//         vat: "25%",
//         CO2e: 0,
//         optionId: "wbrfk6ip",
//         specs: "",
//         quantity: 1,
//       },
//       bbtzwci4: {
//         price: 5000,
//         optionId: "bbtzwci4",
//         quantity: "2",
//         CO2e: 0,
//         each: "2500",
//         option: "LED skärmar huvudentré",
//         info: "pris/st",
//         vat: "25%",
//         selected: true,
//         specs: "",
//       },
//       zxrtrpec: {
//         specs: "",
//         quantity: 1,
//         markUp: 0,
//         price: "6000",
//         CO2e: 0,
//         option: "Konferensduk scen & projektor",
//         vat: "25%",
//         optionId: "zxrtrpec",
//         selected: true,
//         each: "6000",
//         internalCost: 0,
//         info: "",
//       },
//       qazqbzck: {
//         specs: "",
//         option: "Bioduk Cinepro, projektor & surround sound",
//         info: "ink tekniker",
//         CO2e: 0,
//         vat: "25%",
//         each: "16500",
//         price: "16500",
//         quantity: 1,
//         optionId: "qazqbzck",
//       },
//       fovrsigj: {
//         internalCost: "2000",
//         CO2e: 0,
//         optionId: "fovrsigj",
//         price: "2600",
//         markUp: 0,
//         option: "Bildmixer",
//         each: "2600",
//         info: "",
//         vat: "25%",
//         selected: true,
//         specs: "",
//         quantity: 1,
//       },
//       su2bgxsy: {
//         option: "Följespot operatör ",
//         info: "",
//         CO2e: 0,
//         price: "0",
//         quantity: 1,
//         vat: "25%",
//         optionId: "su2bgxsy",
//         each: "0",
//         specs: "",
//       },
//       w4rqkwtc: {
//         option: "DJ utrustning, mixer & två spelare",
//         CO2e: 0,
//         optionId: "w4rqkwtc",
//         specs: "",
//         price: "4000",
//         each: "4000",
//         quantity: 1,
//         vat: "25%",
//         info: "ink. transport",
//       },
//       f54yw2k4: {
//         vat: "25%",
//         selected: true,
//         CO2e: 0,
//         quantity: "5",
//         each: "1250",
//         option: "Mikrofon trådlös/Head-set",
//         markUp: 0,
//         price: 6250,
//         optionId: "f54yw2k4",
//         info: "",
//         specs: "",
//         internalCost: "950",
//       },
//     },
//   },
//   yznp5uyv: {
//     icon: {
//       _store: {},
//       props: {
//         className: "categoryIcon",
//       },
//       _owner: null,
//       key: null,
//       ref: null,
//       type: {
//         type: {},
//         compare: null,
//       },
//     },
//     options: {
//       o7l47esd: {
//         optionId: "o7l47esd",
//         markUp: 0,
//         internalCost: 0,
//         quantity: 1,
//         info: "Bioteknik och tekniker ingår",
//         price: "50000",
//         CO2e: 0,
//         each: "50000",
//         specs: "",
//         option: "Galapaket bio 16.00-23.00",
//         vat: "25%",
//       },
//       kn4gdwvb: {
//         info: "",
//         optionId: "kn4gdwvb",
//         quantity: 1,
//         specs: "",
//         option: "Lokal riv (5h)",
//         each: "40000",
//         vat: "25%",
//         price: "40000",
//         CO2e: 0,
//       },
//       ikyef6xc: {
//         quantity: 1,
//         price: "60000",
//         markUp: 0,
//         option: "Lokal Kväll, 18.00-01.00",
//         vat: "25%",
//         specs: "",
//         CO2e: 0,
//         each: "60000",
//         optionId: "ikyef6xc",
//         info: "",
//         internalCost: "0",
//       },
//       lvjx6qb6: {
//         option: "Lokal halvdag, 08.00-13.00",
//         optionId: "lvjx6qb6",
//         quantity: 1,
//         info: "",
//         price: "50000",
//         CO2e: 0,
//         each: "50000",
//         specs: "",
//         vat: "25%",
//       },
//       gq5nencc: {
//         option: "Eventansvarig ingår",
//         price: 0,
//         CO2e: 0,
//         vat: "25%",
//         selected: true,
//         markUp: 0,
//         info: "",
//         each: 0,
//         optionId: "gq5nencc",
//         internalCost: 0,
//         quantity: 1,
//         specs: "",
//       },
//       "2a7pely3": {
//         vat: "25%",
//         specs: "",
//         each: "35000",
//         option: "Kväll vardag (7h)",
//         CO2e: 0,
//         quantity: 1,
//         internalCost: 0,
//         markUp: 0,
//         info: "",
//         price: "35000",
//         optionId: "2a7pely3",
//       },
//       iqvhkhdx: {
//         option: "Lokal add-on kväll, 18.00-23.00",
//         internalCost: 0,
//         info: "",
//         specs: "",
//         quantity: 1,
//         CO2e: 0,
//         markUp: 0,
//         each: "25000",
//         optionId: "iqvhkhdx",
//         price: "25000",
//         vat: "25%",
//       },
//       cjakiwjq: {
//         optionId: "cjakiwjq",
//         specs: "",
//         info: "",
//         each: "25000",
//         vat: "25%",
//         CO2e: 0,
//         option: "Halvdag",
//         price: "25000",
//         quantity: 1,
//       },
//       gr34momc: {
//         vat: "25%",
//         internalCost: 0,
//         specs: "",
//         each: "-55000",
//         info: "",
//         price: "-55000",
//         optionId: "gr34momc",
//         CO2e: 0,
//         markUp: 0,
//         quantity: 1,
//         selected: true,
//         option: "Rabatt lokalhyra",
//       },
//       xnd7hqxc: {
//         internalCost: 0,
//         optionId: "xnd7hqxc",
//         markUp: 0,
//         CO2e: 0,
//         each: "50000",
//         vat: "25%",
//         quantity: 1,
//         info: "",
//         price: "50000",
//         specs: "",
//         option: "Heldag (8h)",
//       },
//       xmkfpwbv: {
//         CO2e: 0,
//         specs: "",
//         option: "Kväll Fre, Lör, Sön (7h)",
//         each: "40000",
//         quantity: 1,
//         vat: "25%",
//         internalCost: 0,
//         info: "",
//         optionId: "xmkfpwbv",
//         markUp: 0,
//         price: "40000",
//       },
//       o6o7sgad: {
//         internalCost: 0,
//         quantity: 1,
//         markUp: 0,
//         info: "",
//         price: 0,
//         optionId: "o6o7sgad",
//         selected: true,
//         vat: "25%",
//         specs: "",
//         each: 0,
//         option: "Scenmästare, admin 7h, ingår",
//         CO2e: 0,
//       },
//       "7zokdmc5": {
//         each: "2300",
//         optionId: "7zokdmc5",
//         markUp: 0,
//         specs: "",
//         price: "2300",
//         info: "timpris",
//         quantity: 1,
//         CO2e: 0,
//         option: "Lokal extra timmar 06.00-08.00/23.00-01.00",
//         vat: "25%",
//         internalCost: 0,
//       },
//       gayellng: {
//         option: "Frukost (3h)",
//         info: "",
//         each: "20000",
//         price: "20000",
//         CO2e: 0,
//         vat: "25%",
//         markUp: 0,
//         optionId: "gayellng",
//         internalCost: 0,
//         quantity: 1,
//         specs: "",
//       },
//       hne3y4bv: {
//         optionId: "hne3y4bv",
//         CO2e: 0,
//         internalCost: "0",
//         quantity: 1,
//         specs: "",
//         price: "50000",
//         option: "Lokal halvdag, 13.00-18.00",
//         each: "50000",
//         info: "",
//         vat: "25%",
//         markUp: 0,
//       },
//       phozj3po: {
//         specs: "",
//         quantity: 1,
//         each: 0,
//         option: "Inhouse teknik (enl spec) ingår",
//         internalCost: 0,
//         info: "",
//         optionId: "phozj3po",
//         price: 0,
//         markUp: 0,
//         vat: "25%",
//         CO2e: 0,
//         selected: true,
//       },
//       h2tuj7bm: {
//         each: "80000",
//         selected: true,
//         vat: "25%",
//         internalCost: 0,
//         markUp: 0,
//         quantity: 1,
//         option: "Lokal heldag 08.00-18.00",
//         info: "",
//         CO2e: 0,
//         price: "80000",
//         optionId: "h2tuj7bm",
//         specs: "",
//       },
//       "6oqz65oj": {
//         optionId: "6oqz65oj",
//         internalCost: "0",
//         quantity: 1,
//         specs: "",
//         option: "Städning, ingår",
//         price: "0",
//         CO2e: 0,
//         vat: "25%",
//         selected: true,
//         markUp: 0,
//         info: "",
//         each: "0",
//       },
//       unp7yhm3: {
//         price: "40000",
//         markUp: 0,
//         specs: "",
//         optionId: "unp7yhm3",
//         option: "Lokal rigg (5h)",
//         vat: "25%",
//         CO2e: 0,
//         internalCost: 0,
//         quantity: 1,
//         each: "40000",
//         info: "",
//       },
//       t7dcqbwr: {
//         info: "",
//         price: "-10000",
//         optionId: "t7dcqbwr",
//         CO2e: 0,
//         markUp: 0,
//         option: "Barter, lokalhyra",
//         quantity: 1,
//         vat: "25%",
//         internalCost: 0,
//         specs: "",
//         each: "-10000",
//       },
//       "6dfuspzi": {
//         vat: "25%",
//         each: "25000",
//         quantity: 1,
//         specs: "",
//         option: "Byggdag",
//         optionId: "6dfuspzi",
//         price: "25000",
//         CO2e: 0,
//         info: "",
//       },
//     },
//     additional: "Lokalhyra",
//     additionalId: "yznp5uyv",
//     type: "standardFields",
//   },
//   ayj5sbqx: {
//     type: "standardFields",
//     icon: {
//       ref: null,
//       _owner: null,
//       type: {
//         compare: null,
//         type: {},
//       },
//       key: null,
//       props: {
//         className: "categoryIcon",
//       },
//     },
//     additionalId: "ayj5sbqx",
//     options: {
//       "4ekr4c4l": {
//         option: "Bildtekniker",
//         price: "8500",
//         info: "",
//         CO2e: 0,
//         vat: "25%",
//         each: "8500",
//         markUp: 0,
//         optionId: "4ekr4c4l",
//         quantity: 1,
//         internalCost: 0,
//         specs: "",
//       },
//       uomcov3s: {
//         price: "8500",
//         option: "Ljustekniker, heldag",
//         CO2e: 0,
//         markUp: 0,
//         quantity: 1,
//         optionId: "uomcov3s",
//         each: "8500",
//         vat: "25%",
//         specs: "",
//         internalCost: 0,
//         info: "(10 h)",
//       },
//       gb2ntgwg: {
//         info: "",
//         specs: "",
//         quantity: 1,
//         each: "6500",
//         vat: "25%",
//         option: "Ljudtekniker, halvdag",
//         CO2e: 0,
//         markUp: 0,
//         optionId: "gb2ntgwg",
//         internalCost: 0,
//         price: "6500",
//       },
//       dlwx2ao2: {
//         selected: true,
//         quantity: 1,
//         optionId: "dlwx2ao2",
//         specs: " ",
//         option: "Ljudtekniker, heldag",
//         info: "(10 h)",
//         price: "8500",
//         CO2e: 0,
//         vat: "25%",
//         each: "8500",
//       },
//       j5byklpk: {
//         optionId: "j5byklpk",
//         internalCost: 0,
//         markUp: 0,
//         price: "6500",
//         vat: "25%",
//         specs: "",
//         quantity: 1,
//         info: "(5 h)",
//         option: "Ljustekniker, halvdag",
//         CO2e: 0,
//         each: "6500",
//       },
//       "7gqeksjj": {
//         markUp: 0,
//         internalCost: 0,
//         quantity: 1,
//         specs: "",
//         option: "Rabatt, tekniker",
//         info: "",
//         price: "-2000",
//         optionId: "7gqeksjj",
//         CO2e: 0,
//         each: "-2000",
//         vat: "25%",
//       },
//       wvnhlclq: {
//         optionId: "wvnhlclq",
//         option: "Rigg & ljussättning 18 nov. Get-out senast 23.00 19 nov. Alla sopor tas med av kund",
//         quantity: 1,
//         internalCost: "0",
//         info: "",
//         each: "6500",
//         vat: "25%",
//         CO2e: 0,
//         price: "6500",
//         markUp: 0,
//         specs: "",
//       },
//     },
//     additional: "1 Teknik Personal Event",
//   },
//   "6tk2erpo": {
//     additionalId: "6tk2erpo",
//     options: {
//       ispmie3g: {
//         option: "Garderob, obemannad",
//         price: 0,
//         optionId: "ispmie3g",
//         internalCost: "0",
//         quantity: 1,
//         specs: "",
//         markUp: 0,
//         info: "",
//         each: 0,
//         selected: true,
//         CO2e: 0,
//         vat: "25%",
//       },
//       kjlxy7zz: {
//         info: "Minimideb. 7000 kr",
//         CO2e: 0,
//         option: "Garderob",
//         price: "35",
//         each: "35",
//         quantity: 1,
//         specs: " ",
//         vat: "25%",
//         optionId: "kjlxy7zz",
//       },
//     },
//     additional: "Garderob",
//     icon: {
//       _owner: null,
//       type: {
//         compare: null,
//         type: {},
//       },
//       _store: {},
//       ref: null,
//       key: null,
//       props: {
//         className: "categoryIcon",
//       },
//     },
//     type: "standardFields",
//   },
//   y46v46ki: {
//     additional: "5 Catering, mat",
//     icon: {
//       ref: null,
//       _owner: null,
//       type: {
//         compare: null,
//         type: {},
//       },
//       key: null,
//       props: {
//         className: "categoryIcon",
//       },
//     },
//     additionalId: "y46v46ki",
//     type: "standardFields",
//     options: {
//       rbdfbop2: {
//         internalCost: 0,
//         CO2e: 0,
//         price: "80000",
//         quantity: 1,
//         each: "80000",
//         info: "",
//         specs: "",
//         markUp: 0,
//         option: "Förköp Mat & dryck",
//         vat: "25%",
//         optionId: "rbdfbop2",
//       },
//       y3pzsgw4: {
//         each: "36",
//         optionId: "y3pzsgw4",
//         markUp: 0,
//         vat: "12%",
//         specs: "",
//         quantity: 1,
//         option: "Chips, svenska lant ",
//         internalCost: 0,
//         info: "påse 30 gr",
//         CO2e: 0,
//         price: "36",
//       },
//       b62isvay: {
//         option: "Mat tillkommer enligt önskemål",
//         optionId: "b62isvay",
//         CO2e: 0,
//         specs: "",
//         internalCost: 0,
//         each: "0",
//         info: "",
//         price: "0",
//         quantity: 1,
//         vat: "25%",
//         markUp: 0,
//       },
//       g74fpwhm: {
//         markUp: 0,
//         internalCost: 0,
//         each: "-190",
//         optionId: "g74fpwhm",
//         price: "-190",
//         selected: false,
//         option: "Rabatt mat",
//         quantity: 1,
//         specs: "",
//         vat: "25%",
//         info: "",
//         CO2e: 0,
//       },
//       "6lotfp5q": {
//         CO2e: 0,
//         quantity: 1,
//         option: "Canapéer i Wontonskal",
//         each: "55",
//         internalCost: 0,
//         vat: "12%",
//         specs: "",
//         info: "Lax/Shiitake/Råbiff",
//         markUp: 0,
//         price: "55",
//         optionId: "6lotfp5q",
//       },
//       b3is5o4m: {
//         specs: "",
//         internalCost: 0,
//         each: "500",
//         CO2e: 0,
//         vat: "25%",
//         option: "Middag Maxims vego choice, Liwlig special",
//         info: "enl. ök",
//         quantity: 1,
//         markUp: 0,
//         price: "500",
//         optionId: "b3is5o4m",
//       },
//       umcrqru2: {
//         optionId: "umcrqru2",
//         internalCost: "74",
//         info: "Veg",
//         specs: "",
//         each: "125",
//         vat: "12%",
//         selected: true,
//         markUp: 0,
//         option: "Wraps Chef choice",
//         CO2e: 0,
//         quantity: "10",
//         price: 1250,
//       },
//       zc45wc45: {
//         optionId: "zc45wc45",
//         price: "55",
//         specs: "",
//         quantity: 1,
//         each: "55",
//         internalCost: 0,
//         option: "Oliver, skål",
//         CO2e: 0,
//         vat: "25%",
//         markUp: 0,
//         info: "",
//       },
//       tz2rb2lb: {
//         option: "Popcorn, i bägare (50cl)",
//         CO2e: 0,
//         quantity: 1,
//         price: "35",
//         each: "35",
//         vat: "12%",
//         internalCost: 0,
//         info: "",
//         markUp: 0,
//         specs: "",
//         optionId: "tz2rb2lb",
//       },
//       ldaf2zrm: {
//         markUp: 0,
//         optionId: "ldaf2zrm",
//         CO2e: 0,
//         each: "60",
//         info: "",
//         specs: "",
//         internalCost: 0,
//         vat: "12%",
//         option: "Mandlar, Marcona lyx (80gr)",
//         price: "60",
//         quantity: 1,
//       },
//       gsrgq46a: {
//         info: "",
//         quantity: 1,
//         price: "70",
//         each: "70",
//         optionId: "gsrgq46a",
//         vat: "12%",
//         internalCost: 0,
//         specs: "",
//         option: "Spetskålsallad, sesam, salladslök, koriander, furikake",
//         CO2e: 0,
//         markUp: 0,
//       },
//       svp5fnob: {
//         CO2e: 0,
//         option: "Semla",
//         price: "59",
//         quantity: 1,
//         markUp: 0,
//         each: "59",
//         internalCost: "45",
//         info: "finns även gluten/laktis/nötfri",
//         vat: "12%",
//         optionId: "svp5fnob",
//         specs: "",
//       },
//       ojttoweo: {
//         price: 13000,
//         quantity: "200",
//         option: "Fikabröd, kaffe/te, vattenkaraff",
//         CO2e: 0,
//         optionId: "ojttoweo",
//         internalCost: "42",
//         info: "val: smoothie/bulle/raw bar",
//         selected: true,
//         each: "65",
//         markUp: 0,
//         vat: "25%",
//         specs: "",
//       },
//     },
//   },
//   yhdw3bje: {
//     options: {
//       qnueant6: {
//         quantity: 1,
//         CO2e: 0,
//         specs: "",
//         internalCost: 0,
//         each: "60",
//         info: "33 cl",
//         option: "Cider, Briska Äpple, Alkoholfri",
//         price: "60",
//         vat: "12%",
//         markUp: 0,
//         optionId: "qnueant6",
//       },
//       gq4nwq6b: {
//         markUp: 0,
//         vat: "25%",
//         CO2e: 0,
//         internalCost: 0,
//         each: 0,
//         optionId: "gq4nwq6b",
//         info: "se policy dokument tillstånd",
//         specs: "",
//         price: 0,
//         quantity: 1,
//         option: "Spons: Serveringsavgift 25% av inköpspris",
//       },
//       hmxximpo: {
//         info: "",
//         CO2e: 0,
//         internalCost: 0,
//         option: "Kaffe/te, vattenkaraff",
//         vat: "12%",
//         optionId: "hmxximpo",
//         markUp: 0,
//         price: 300,
//         specs: "",
//         each: "20",
//         quantity: "15",
//         selected: false,
//       },
//       dner4vak: {
//         CO2e: 0,
//         vat: "12%",
//         info: "burk",
//         each: "32",
//         price: "32",
//         markUp: 0,
//         optionId: "dner4vak",
//         internalCost: 0,
//         option: "Läsk, Cola, Fanta, Sprite",
//         quantity: 1,
//         specs: "",
//       },
//       "6ijcr46f": {
//         markUp: 0,
//         vat: "12%",
//         optionId: "6ijcr46f",
//         info: "ca 6 glas",
//         internalCost: "",
//         specs: "",
//         each: "400",
//         CO2e: 0,
//         option: "Alkofritt bubbel, Chavin Chardonnay, flaska",
//         price: "400",
//         quantity: 1,
//       },
//       uvirhdwl: {
//         each: "100",
//         vat: "25%",
//         markUp: 0,
//         quantity: 1,
//         specs: "",
//         internalCost: 0,
//         option: "Vin husets, eko, glas",
//         info: "",
//         optionId: "uvirhdwl",
//         CO2e: 0,
//         price: "100",
//       },
//       zxrc7qs6: {
//         price: "25000",
//         CO2e: 0,
//         option: "Galapaket förköp dryck/mat",
//         quantity: 1,
//         markUp: 0,
//         optionId: "zxrc7qs6",
//         specs: "",
//         internalCost: 0,
//         info: "personal ingår",
//         vat: "25%",
//         each: "25000",
//       },
//       ga5smchi: {
//         price: "480",
//         markUp: 0,
//         CO2e: 0,
//         internalCost: 0,
//         each: "480",
//         quantity: 1,
//         option: "Cava, husets Signat Brut, flaska",
//         specs: "",
//         optionId: "ga5smchi",
//         vat: "25%",
//         info: "ca 6 glas",
//       },
//       vxuczue3: {
//         specs: "",
//         CO2e: 0,
//         optionId: "vxuczue3",
//         markUp: 0,
//         each: "428",
//         info: "ca 4,5 glas",
//         internalCost: 0,
//         option: "Vin husets, eko, flaska",
//         vat: "25%",
//         quantity: 1,
//         price: "428",
//       },
//     },
//     additional: "4 Catering, dryck",
//     icon: {
//       key: null,
//       props: {
//         className: "categoryIcon",
//       },
//       type: {
//         compare: null,
//         type: {},
//       },
//       ref: null,
//       _owner: null,
//     },
//     type: "standardFields",
//     additionalId: "yhdw3bje",
//   },
//   ljizlq2w: {
//     icon: {
//       type: {
//         compare: null,
//         type: {},
//       },
//       key: null,
//       props: {
//         className: "categoryIcon",
//       },
//       ref: null,
//       _owner: null,
//       _store: {},
//     },
//     additionalId: "ljizlq2w",
//     type: "standardFields",
//     additional: "Övrigt",
//     options: {
//       yhzgu36k: {
//         info: " ",
//         CO2e: 0,
//         option: "· Foajé- och biljettpersonal",
//         optionId: "yhzgu36k",
//         each: 0,
//         vat: "25%",
//         quantity: 1,
//         specs: " ",
//         price: 0,
//       },
//       "5yqhgogn": {
//         optionId: "5yqhgogn",
//         vat: "25%",
//         option: "· Biljetthantering",
//         specs: "",
//         each: "5000",
//         CO2e: 0,
//         info: "",
//         price: "5000",
//         quantity: 1,
//       },
//       lb5hl3ld: {
//         selected: false,
//         CO2e: 0,
//         optionId: "lb5hl3ld",
//         specs: "",
//         price: 0,
//         vat: "25%",
//         info: "",
//         quantity: 1,
//         each: 0,
//         option: "· Scenmästare",
//       },
//     },
//   },
//   "6zpyrve2": {
//     additional: "6 Inredning",
//     options: {
//       u42kyu5l: {
//         info: "",
//         markUp: 0,
//         option: "Barstol, scen",
//         each: "600",
//         internalCost: "400",
//         specs: "",
//         quantity: 1,
//         CO2e: 0,
//         price: "600",
//         optionId: "u42kyu5l",
//         vat: "25%",
//       },
//       eznvqfmv: {
//         CO2e: 0,
//         vat: "25%",
//         info: "runt, ink strumpa",
//         option: "Ståbord",
//         each: "750",
//         price: "750",
//         optionId: "eznvqfmv",
//         internalCost: "211",
//         quantity: 1,
//         specs: " ",
//       },
//       "65qvhtkj": {
//         optionId: "65qvhtkj",
//         price: 0,
//         internalCost: 0,
//         quantity: 1,
//         specs: "",
//         markUp: 0,
//         selected: true,
//         CO2e: 0,
//         vat: "25%",
//         info: "",
//         each: 0,
//         option: "Möblering scen, enl önskemål",
//       },
//       y62adff3: {
//         quantity: 1,
//         specs: "",
//         vat: "25%",
//         CO2e: 0,
//         info: "",
//         each: "1800",
//         option: "Hyrgods, bud (t.o.r.)",
//         markUp: 0,
//         price: "1800",
//         optionId: "y62adff3",
//         internalCost: "1390",
//       },
//     },
//     type: "standardFields",
//     additionalId: "6zpyrve2",
//     icon: {
//       _owner: null,
//       props: {
//         className: "categoryIcon",
//       },
//       type: {
//         type: {},
//         compare: null,
//       },
//       ref: null,
//       key: null,
//     },
//   },
//   vvcgql7n: {
//     additionalId: "vvcgql7n",
//     type: "standardFields",
//     additional: "Free Text",
//     options: {
//       aip4pgea: {
//         optionId: "aip4pgea",
//         info: "Get-in 08.00, program 10:00-16:00. Mingel med betalbar fram till 19:00",
//         markUp: 0,
//         quantity: 1,
//         internalCost: 0,
//         vat: "25%",
//         option: "AI Conference",
//         each: 0,
//         CO2e: 0,
//         price: 0,
//         specs: "",
//       },
//       tzdq6syz: {
//         internalCost: 0,
//         specs: "",
//         CO2e: 0,
//         each: 0,
//         vat: "25%",
//         option: "TV4",
//         info: "Maxim bokat 22/9 08.00-20,00 & 23/9 06.00-14.00. Antal gäster: 250-350 st",
//         markUp: 0,
//         quantity: 1,
//         price: 0,
//         optionId: "tzdq6syz",
//       },
//       qalzjlzk: {
//         optionId: "qalzjlzk",
//         markUp: 0,
//         specs: "",
//         quantity: 1,
//         each: 0,
//         internalCost: 0,
//         vat: "25%",
//         price: 0,
//         option: "MIS",
//         CO2e: 0,
//         info: "Antal gäster 250 st. Get-in 08.00, scenprogram & mingel 12.30-19.00. Betalbar & Bistro Maxim öppen efteråt. OBS Load out dagen efter vid öppen Bistro!",
//       },
//       jd6iglmg: {
//         option: "Rexeffect",
//         info: "Pris enl tidigare ök: 55.000 kr för halvdag, ink tekniker & in-house teknik. Beräknat antal gäster: 250 st",
//         price: 0,
//         specs: "",
//         each: 0,
//         quantity: 1,
//         vat: "25%",
//         CO2e: 0,
//         internalCost: 0,
//         markUp: 0,
//         optionId: "jd6iglmg",
//       },
//       ng6kh3gx: {
//         specs: "",
//         option: "I samarbete med K-märkt...",
//         CO2e: 0,
//         vat: "25%",
//         quantity: 1,
//         optionId: "ng6kh3gx",
//         internalCost: 0,
//         markUp: 0,
//         each: 0,
//         price: 0,
//         info: "I samarbete med K-märkt. Cirka 300 gäster",
//       },
//       eojmbcso: {
//         CO2e: 0,
//         each: 0,
//         price: 0,
//         optionId: "eojmbcso",
//         markUp: 0,
//         vat: "25%",
//         info: "Event 13.00-19.00, med betalbar från 17.00. Ca 370 gäster",
//         quantity: 1,
//         specs: "",
//         internalCost: 0,
//         option: "Bokbranschdagen",
//       },
//       uqll5xaw: {
//         CO2e: 0,
//         internalCost: 0,
//         quantity: 1,
//         each: 0,
//         vat: "25%",
//         price: 0,
//         option: "Freetext Nöjesguiden",
//         specs: "",
//         info: "Ca 350-400 gäster. Rigg & rep 13.00. Maxim står för bartenders 20.00-23.00.",
//         optionId: "uqll5xaw",
//         markUp: 0,
//       },
//       qb7uo5ey: {
//         vat: "25%",
//         each: 0,
//         optionId: "qb7uo5ey",
//         quantity: 1,
//         price: 0,
//         info: "Gäster: 350. Rigg & rep dagen innan. Event genomförande 17.00-21.00. Betalbar 21.00-23.00",
//         internalCost: 0,
//         markUp: 0,
//         option: "UNHCR",
//         CO2e: 0,
//         specs: "",
//       },
//       ncii5563: {
//         optionId: "ncii5563",
//         option: "Offert",
//         specs: " ",
//         CO2e: 0,
//         vat: "25%",
//         price: 0,
//         quantity: 1,
//         each: 0,
//         info: "Lägg text här... ✅",
//       },
//     },
//     icon: {
//       _owner: null,
//       type: {
//         type: {},
//         compare: null,
//       },
//       ref: null,
//       props: {
//         className: "categoryIcon",
//       },
//       key: null,
//     },
//   },
// };

// const traveltestdata = [
//   {
//     visitors: 354,
//     distance: "66.87",
//     climateImpact: "3 195.73",
//     travel: 480,
//     carDistance: "1 946.27",
//     bicycleDistance: "328.92",
//     busDistance: "2 709.75",
//     trainDistance: "13 843.42",
//     planeDistance: "359.83",
//     averageImpact: "9.03",
//     carShare: "11.10",
//     bicycleShare: "10.59",
//     busShare: "18.31",
//     trainShare: "59.81",
//     planeShare: "0.19",
//   },
//   {
//     visitors: 286,
//     distance: "166.89",
//     climateImpact: "6 443.59",
//     travel: 480,
//     carDistance: "6 245.40",
//     bicycleDistance: "195.97",
//     busDistance: "5 135.03",
//     trainDistance: "27 790.53",
//     planeDistance: "847.64",
//     averageImpact: "22.53",
//     carShare: "16.29",
//     bicycleShare: "6.50",
//     busShare: "17.20",
//     trainShare: "59.60",
//     planeShare: "0.40",
//   },
//   {
//     visitors: 56,
//     distance: "21.97",
//     climateImpact: "166.08",
//     travel: 480,
//     carDistance: "76.83",
//     bicycleDistance: "54.44",
//     busDistance: "189.64",
//     trainDistance: "738.15",
//     planeDistance: "0",
//     averageImpact: "2.97",
//     carShare: "10.89",
//     bicycleShare: "10.18",
//     busShare: "18.93",
//     trainShare: "60",
//     planeShare: "0",
//   },
//   {
//     visitors: 54,
//     distance: "83.91",
//     climateImpact: "611.73",
//     travel: 480,
//     carDistance: "1 235.79",
//     bicycleDistance: "36.25",
//     busDistance: "540.48",
//     trainDistance: "2 652.64",
//     planeDistance: "66.14",
//     averageImpact: "11.33",
//     carShare: "15.74",
//     bicycleShare: "6.11",
//     busShare: "18.15",
//     trainShare: "59.78",
//     planeShare: "0.22",
//   },
//   {
//     visitors: 457,
//     distance: "37.65",
//     climateImpact: "2 216.65",
//     travel: 480,
//     carDistance: "944.18",
//     bicycleDistance: "477.15",
//     busDistance: "2 204.99",
//     trainDistance: "8 499.82",
//     planeDistance: "2 995.73",
//     averageImpact: "4.85",
//     carShare: "9.26",
//     bicycleShare: "11.62",
//     busShare: "19.04",
//     trainShare: "59.83",
//     planeShare: "0.26",
//   },
//   {
//     visitors: 264,
//     distance: "100.42",
//     climateImpact: "3 456.93",
//     travel: 480,
//     carDistance: "2 945.97",
//     bicycleDistance: "265.10",
//     busDistance: "2 908.71",
//     trainDistance: "13 568.04",
//     planeDistance: "3 683.47",
//     averageImpact: "13.09",
//     carShare: "10.44",
//     bicycleShare: "10.97",
//     busShare: "18.37",
//     trainShare: "59.45",
//     planeShare: "0.77",
//   },
//   {
//     visitors: 225,
//     distance: "124.57",
//     climateImpact: "3 544.59",
//     travel: 480,
//     carDistance: "1 191.09",
//     bicycleDistance: "156.68",
//     busDistance: "3 075.40",
//     trainDistance: "12 641.74",
//     planeDistance: "6 809.55",
//     averageImpact: "15.75",
//     carShare: "12.40",
//     bicycleShare: "8.80",
//     busShare: "18.44",
//     trainShare: "59.31",
//     planeShare: "1.05",
//   },
//   {
//     visitors: 422,
//     distance: "126.74",
//     climateImpact: "7 190.44",
//     travel: 480,
//     carDistance: "5 021.97",
//     bicycleDistance: "351.95",
//     busDistance: "5 760.54",
//     trainDistance: "30 701.07",
//     planeDistance: "1 718.94",
//     averageImpact: "17.04",
//     carShare: "14.10",
//     bicycleShare: "8.53",
//     busShare: "17.32",
//     trainShare: "59.55",
//     planeShare: "0.50",
//   },
//   {
//     visitors: 465,
//     distance: "123.95",
//     climateImpact: "7 313.05",
//     travel: 480,
//     carDistance: "3 009.30",
//     bicycleDistance: "489.18",
//     busDistance: "6 238.53",
//     trainDistance: "26 353.83",
//     planeDistance: "13 385.72",
//     averageImpact: "15.73",
//     carShare: "10.27",
//     bicycleShare: "11.06",
//     busShare: "18.58",
//     trainShare: "59.70",
//     planeShare: "0.38",
//   },
//   {
//     visitors: 338,
//     distance: "138.86",
//     climateImpact: "6 336.39",
//     travel: 480,
//     carDistance: "3 245.00",
//     bicycleDistance: "236.80",
//     busDistance: "5 111.45",
//     trainDistance: "27 357.18",
//     planeDistance: "804.57",
//     averageImpact: "18.75",
//     carShare: "17.25",
//     bicycleShare: "6.04",
//     busShare: "16.72",
//     trainShare: "59.57",
//     planeShare: "0.43",
//   },
//   {
//     visitors: 395,
//     distance: "45.83",
//     climateImpact: "2 443.96",
//     travel: 480,
//     carDistance: "1 117.80",
//     bicycleDistance: "398.02",
//     busDistance: "2 264.65",
//     trainDistance: "10 630.17",
//     planeDistance: "231.86",
//     averageImpact: "6.19",
//     carShare: "11.13",
//     bicycleShare: "10.22",
//     busShare: "18.66",
//     trainShare: "59.86",
//     planeShare: "0.14",
//   },
//   {
//     visitors: 422,
//     distance: "47.10",
//     climateImpact: "2 683.18",
//     travel: 480,
//     carDistance: "1 362.12",
//     bicycleDistance: "341.27",
//     busDistance: "2 426.60",
//     trainDistance: "11 695.79",
//     planeDistance: "229.47",
//     averageImpact: "6.36",
//     carShare: "12.39",
//     bicycleShare: "9.45",
//     busShare: "18.15",
//     trainShare: "59.86",
//     planeShare: "0.14",
//   },
//   {
//     visitors: 406,
//     distance: "102.24",
//     climateImpact: "5 603.70",
//     travel: 480,
//     carDistance: "2 502.66",
//     bicycleDistance: "291.32",
//     busDistance: "4 588.22",
//     trainDistance: "24 231.72",
//     planeDistance: "673.63",
//     averageImpact: "13.80",
//     carShare: "15.25",
//     bicycleShare: "7.54",
//     busShare: "17.22",
//     trainShare: "59.67",
//     planeShare: "0.33",
//   },
//   {
//     visitors: 422,
//     distance: "95.18",
//     climateImpact: "5 422.58",
//     travel: 480,
//     carDistance: "2 444.81",
//     bicycleDistance: "263.01",
//     busDistance: "4 450.23",
//     trainDistance: "23 512.97",
//     planeDistance: "587.40",
//     averageImpact: "12.85",
//     carShare: "17.01",
//     bicycleShare: "6.54",
//     busShare: "16.45",
//     trainShare: "59.66",
//     planeShare: "0.34",
//   },
//   {
//     visitors: 391,
//     distance: "80.10",
//     climateImpact: "3 982.23",
//     travel: 480,
//     carDistance: "2 073.72",
//     bicycleDistance: "300.18",
//     busDistance: "3 555.63",
//     trainDistance: "14 557.66",
//     planeDistance: "6 943.81",
//     averageImpact: "10.18",
//     carShare: "13.43",
//     bicycleShare: "8.52",
//     busShare: "17.75",
//     trainShare: "59.43",
//     planeShare: "0.88",
//   },
//   {
//     visitors: 467,
//     distance: "29.97",
//     climateImpact: "1 889.69",
//     travel: 480,
//     carDistance: "687.66",
//     bicycleDistance: "381.67",
//     busDistance: "1 961.21",
//     trainDistance: "8 303.37",
//     planeDistance: "95.26",
//     averageImpact: "4.05",
//     carShare: "11.47",
//     bicycleShare: "9.73",
//     busShare: "18.80",
//     trainShare: "59.95",
//     planeShare: "0.05",
//   },
//   {
//     visitors: 450,
//     distance: "107.66",
//     climateImpact: "6 310.86",
//     travel: 480,
//     carDistance: "3 653.30",
//     bicycleDistance: "361.78",
//     busDistance: "5 244.62",
//     trainDistance: "24 786.60",
//     planeDistance: "6 811.43",
//     averageImpact: "14.02",
//     carShare: "14.66",
//     bicycleShare: "8.43",
//     busShare: "16.87",
//     trainShare: "59.68",
//     planeShare: "0.36",
//   },
//   {
//     visitors: 165,
//     distance: "37.76",
//     climateImpact: "841.05",
//     travel: 480,
//     carDistance: "643.25",
//     bicycleDistance: "162.94",
//     busDistance: "811.76",
//     trainDistance: "3 659.50",
//     planeDistance: "78.52",
//     averageImpact: "5.10",
//     carShare: "9.09",
//     bicycleShare: "11.45",
//     busShare: "19.45",
//     trainShare: "59.96",
//     planeShare: "0.04",
//   },
//   {
//     visitors: 510,
//     distance: "114.54",
//     climateImpact: "7 258.33",
//     travel: 480,
//     carDistance: "2 130.25",
//     bicycleDistance: "607.31",
//     busDistance: "6 443.59",
//     trainDistance: "24 410.38",
//     planeDistance: "17 560.00",
//     averageImpact: "14.23",
//     carShare: "11.75",
//     bicycleShare: "10.03",
//     busShare: "18.14",
//     trainShare: "59.76",
//     planeShare: "0.31",
//   },
//   {
//     visitors: 513,
//     distance: "147.33",
//     climateImpact: "9 485.04",
//     travel: 480,
//     carDistance: "6 184.42",
//     bicycleDistance: "533.02",
//     busDistance: "8 126.15",
//     trainDistance: "32 952.63",
//     planeDistance: "20 309.05",
//     averageImpact: "18.49",
//     carShare: "11.54",
//     bicycleShare: "10.06",
//     busShare: "18.25",
//     trainShare: "59.59",
//     planeShare: "0.56",
//   },
//   {
//     visitors: 661,
//     distance: "39.62",
//     climateImpact: "3 488.92",
//     travel: 480,
//     carDistance: "1 818.22",
//     bicycleDistance: "707.09",
//     busDistance: "3 392.17",
//     trainDistance: "14 673.16",
//     planeDistance: "1 551.77",
//     averageImpact: "5.28",
//     carShare: "9.95",
//     bicycleShare: "11.03",
//     busShare: "18.99",
//     trainShare: "59.86",
//     planeShare: "0.17",
//   },
//   {
//     visitors: 565,
//     distance: "69.39",
//     climateImpact: "5 292.37",
//     travel: 480,
//     carDistance: "4 475.53",
//     bicycleDistance: "430.78",
//     busDistance: "4 536.12",
//     trainDistance: "22 990.02",
//     planeDistance: "531.60",
//     averageImpact: "9.37",
//     carShare: "15.14",
//     bicycleShare: "7.67",
//     busShare: "17.19",
//     trainShare: "59.73",
//     planeShare: "0.27",
//   },
//   {
//     visitors: 523,
//     distance: "104.74",
//     climateImpact: "7 083.32",
//     travel: 480,
//     carDistance: "3 953.69",
//     bicycleDistance: "459.11",
//     busDistance: "6 068.10",
//     trainDistance: "27 163.53",
//     planeDistance: "9 143.53",
//     averageImpact: "13.54",
//     carShare: "14.19",
//     bicycleShare: "8.15",
//     busShare: "17.44",
//     trainShare: "59.41",
//     planeShare: "0.82",
//   },
// ];
