/** @format */

import { Avatar, CircularProgress, Divider, Grid, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { storeChosenVenues, storeMultiVenueBookings } from "../../features/venueFilter/venueFilterSlice";
import { useDispatch } from "react-redux";
import { db } from "../../firebase";
import { collection, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { fetchCachedData } from "../Sidebar/IndexedDBForCurrentVenueBookings";
import setListenerToVenueBookings from "../../Listeners/setListenerToVenueBookings";

const BookingViewOptions = ({ setNumberOfVenuesToView }) => {
  const accessibleVenues = useSelector((state) => state.counter.accessibleVenues);
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const venueAccess = useSelector((state) => state.counter.venueAccess);
  const dispatch = useDispatch();
  // Initialize state as an array.
  const [chosenVenues, setChosenVenues] = React.useState(venueAccess);
  const multiVenueBookings = useSelector((state) => state.counter.multiVenueBookings);
  const [bookings, setBookings] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const handleChosen = (event, newChosenVenues) => {
    setChosenVenues(newChosenVenues);
  };

  //   useEffect(() => {
  //     setChosenVenues([currentVenue.venueid]);
  //   }, [currentVenue.venueid]);

  useEffect(() => {
    setNumberOfVenuesToView(chosenVenues.length);
    setShownBookings();
  }, [chosenVenues]);

  const fetchVenueBookings = async (venueid) => {
    // setLoading(true);
    const q = query(collection(db, "Bookings"), where("venueid", "==", venueid));
    const querySnapshot = await getDocs(q);
    const bookings = [];
    querySnapshot.forEach((doc) => {
      bookings.push({ id: doc.id, ...doc.data() });
      console.log("doc.data()", doc.data());
    });
    // setLoading(false);
    return bookings;
  };

  async function setShownBookings() {
    let tmp = [];
    for (const venueid of chosenVenues) {
      const posts = await fetchVenueBookings(venueid);
      const filteredPosts = posts.filter(
        (post) => post.status === "Confirmed" || post.status === "Pending Request" || post.status === "Awaiting Artist"
      );
      tmp.push(...filteredPosts);
      //   console.log("TMP=====>", tmp);
    }
    dispatch(storeMultiVenueBookings(tmp));
  }

  useEffect(() => {
    console.log("multiVenueBookings", multiVenueBookings);
    setBookings(multiVenueBookings);
  }, [multiVenueBookings]);

  useEffect(() => {
    dispatch(storeChosenVenues(chosenVenues));
  }, [chosenVenues]);

  const isSelected = (venueid) => {
    return chosenVenues.includes(venueid);
  };

  return (
    <Grid container xs={12}>
      {/* <CircularProgress style={{ display: loading ? "block" : "none" }} /> */}
      {/* {multiVenueBookings.length ? `Selected: ${multiVenueBookings.length} venues` : "No Venue Selected"} */}
      {/* {chosenVenues.length ? `Selected: ${chosenVenues.join(", ")}` : "No Venue Selected"} */}
      {/* <ToggleButtonGroup
        value={chosenVenues} // value is now an array
        size="small"
        aria-label="small button group"
        onChange={handleChosen}
      >
        {accessibleVenues.map((venue) => (
          <ToggleButton size="small" value={venue.venueid} key={venue.venueid} style={styleVenueToggleButton} className="venueToggleButton">
            <Tooltip title={venue.venue} placement="top">
              <Avatar
                src={venue.venueImage}
                alt={venue.name}
                style={{
                  opacity: isSelected(venue.venueid) ? "1" : "0.3",
                  border: isSelected(venue.venueid) ? "1px dotted #fff" : "none",
                }}
              />
            </Tooltip>
          </ToggleButton>
        ))}
      </ToggleButtonGroup> */}
    </Grid>
  );
};

export default BookingViewOptions;

const styleVenueToggleButton = {
  background: "transparent",
  borderRadius: "50%",
  border: "none",
};
