/** @format */

import { Button, FormControl, InputLabel, MenuItem, Select, Tooltip } from "@mui/material";
import { doc, setDoc, Timestamp, updateDoc } from "firebase/firestore";
import React, { useEffect } from "react";
import { useState } from "react";
import { db } from "../../firebase";
import ConfirmDialog from "../../helpers/ConfirmDialog";
import { useSelector } from "react-redux";
import LockIcon from "@mui/icons-material/Lock";

const SelectStatus = ({ bookingData, setBookingData, agreement, setLoading }) => {
  const [newValue, setNewValue] = useState();
  const [removingSignatures, setRemovingSignatures] = useState(false);
  const [formLock, setFormLock] = useState(false);
  const superOwner = useSelector((state) => state.counter.superOwner);
  const bookingsLength = agreement && agreement.bookingids ? agreement.bookingids.length : 0;
  const newSignturesRequired =
    agreement && (agreement.agentSignature || agreement.venueSignature)
      ? `New signatures required.
  `
      : "";
  const venueSignedBy = agreement && agreement.venueSignature ? `- Removing venue signature by ${agreement.venueSignature.userId}.` : "";
  const agentSignedBy =
    agreement && agreement.agentSignature
      ? `
  - Removing customer signature by ${agreement.agentSignature.userId}.`
      : "";
  const handleChange = async () => {
    if ((!removingSignatures && agreement && agreement.venueSignature) || (!removingSignatures && agreement && agreement.agentSignature)) {
      setRemovingSignatures(true);
      return;
    }
    if (removingSignatures) {
      removeSignatures();
    }
    setLoading(true);
    // update bookingdata
    setBookingData((prev) => ({ ...prev, status: newValue, updated: Timestamp.fromDate(new Date()) }));
    // update agreement
    await setDoc(doc(db, "Agreements", bookingData.agreementId), { status: newValue }, { merge: true });
    //update all the bookings
    const updatePromises = agreement.bookingids.map((bookingId) =>
      setDoc(doc(db, "Bookings", bookingId), { status: newValue, updated: Timestamp.fromDate(new Date()) }, { merge: true })
    );
    await Promise.all(updatePromises);
    setRemovingSignatures(false);
    setLoading(false);
  };

  async function removeSignatures() {
    // alert("removing signatures");
    const previousVenueSignature = agreement.venueSignature;
    const previousAgentSignature = agreement.agentSignature;
    // update agreement with empty signatures and setting previous signatures (for safety matters)
    await setDoc(
      doc(db, "Agreements", bookingData.agreementId),
      { previousVenueSignature, previousAgentSignature, venueSignature: "", agentSignature: "" },
      { merge: true }
    );
  }

  function dontRemoveSignatures() {
    setRemovingSignatures(false);
  }

  function restoreVenueSignature() {
    updateDoc(doc(db, "Agreements", bookingData.agreementId), {
      venueSignature: agreement.previousVenueSignature,
      previousVenueSignature: "",
    });
  }

  function restoreAgentSignature() {
    updateDoc(doc(db, "Agreements", bookingData.agreementId), {
      agentSignature: agreement.previousAgentSignature,
      previousAgentSignature: "",
    });
  }

  console.log("selectstatus", agreement);

  useEffect(() => {
    bookingData.status === "Confirmed" && setFormLock(true);
  }, [bookingData.status]);

  if (agreement)
    return (
      <>
        <ConfirmDialog
          disabled={formLock}
          onConfirm={handleChange}
          header={bookingsLength > 1 ? `Change status for ${bookingsLength} bookings? ` : `Change status for ${bookingsLength} booking? `}
          message={`${newSignturesRequired} ${venueSignedBy} ${agentSignedBy}`}
        >
          <FormControl fullWidth>
            {/* <InputLabel id="demo-simple-select-label">Status</InputLabel> */}
            <Select
              // sx={{ height: "30px", color: "transparent" }}
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={bookingData.status}
              label="Status"
              // onChange={(e) => handleChange(e.target.value)}
              onChange={(e) => setNewValue(e.target.value)}
            >
              <MenuItem value={"Pending Request"}>
                <b style={{ color: "red", marginRight: "15px" }}>█</b>
                <span style={{ color: "white" }}> Pending Request</span>
              </MenuItem>
              <MenuItem value={"Awaiting Artist"}>
                <b style={{ color: "orange", marginRight: "15px" }}>█</b>
                <span style={{ color: "white" }}> Awaiting Artist</span>
              </MenuItem>
              <MenuItem value={"Confirmed"}>
                <b style={{ color: "green", marginRight: "15px" }}>█</b>
                <span style={{ color: "white" }}> Confirmed</span>
              </MenuItem>
            </Select>
          </FormControl>
        </ConfirmDialog>
        {formLock && (
          <Tooltip title="Unlock form to change status">
            <Button onClick={() => setFormLock(false)}>
              <LockIcon />
            </Button>
          </Tooltip>
        )}
        {removingSignatures && (
          <ConfirmDialog
            onConfirm={handleChange}
            onCancel={dontRemoveSignatures}
            // onCancel={alert("cancel")}
            // onCancel={setRemovingSignatures(false)}
            header={`Remove signatures?`}
            message={`Are you sure you want to clear signatures from this agreement?`}
            open={true}
          ></ConfirmDialog>
        )}
        {superOwner && agreement.previousVenueSignature && agreement.previousVenueSignature.userId && (
          <Button onClick={() => restoreVenueSignature()}>Restore previous venue signature</Button>
        )}
        {superOwner && agreement.previousAgentSignature && agreement.previousAgentSignature.userId && (
          <Button onClick={() => restoreAgentSignature()}>Restore previous customer signature</Button>
        )}
      </>
    );
};

export default SelectStatus;
